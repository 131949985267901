import React, { useRef, useState } from "react";
import "./index.css";
import InputForm from "../../components/inputForm/inputForm";
import terminIcon from "../../assets/terminIcon.png";
import NavBarDesktop from "../navbarNews/navbarNewsDesktop";
import Footer from "../footer/footer";
import { render } from "@react-email/render";
import AWS from "aws-sdk";
import Template from "../../components/inputForm/TemplateInput";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { DatePicker, Space } from "antd";

AWS.config.update({
  region: process.env.REACT_APP_AWS_SES_REGION,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
});

const Termin = ({ setIsEnglish, isEnglish }) => {
  const [formValues, setFormValues] = useState({
    user_name: "",
    user_email: "",
    user_enterprise: "",
    user_emloyeeNumber: "",
    user_message: "",
    user_language: "",
    user_selectedDate: null,
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const form = useRef();

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const sendEmail = async (e, formValues) => {
    e.preventDefault();

    if (!isValidEmail(formValues.user_email)) {
      setError("Invalid email address");
      return;
    }

    const emailHtml = render(<Template data={formValues} />);
    const options = {
      Source: "info@oneri.de",
      Destination: {
        ToAddresses: ["info@oneri.de", "rinor@oneri.de"],
        CcAddresses: ["Rinor.hatashi@tframe.de"],
      },
      Message: {
        Body: {
          Html: {
            Charset: "UTF-8",
            Data: emailHtml,
          },
        },
        Subject: {
          Charset: "UTF-8",
          Data: "Contact Oneri",
        },
      },
    };

    try {
      await new AWS.SES({ apiVersion: "2010-12-01" })
        .sendEmail(options)
        .promise();
      form.current?.reset();
      toast(
        isEnglish
          ? "Thank you for contacting us. We will reach you shortly!"
          : "Danke, dass Sie uns kontaktiert haben. Wir werden Sie in Kürze erreichen!"
      );
      navigate("/termin/success");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="termin__container">
      <NavBarDesktop setIsEnglish={setIsEnglish} isEnglish={isEnglish} />
      <div className="termin__container__mainPage">
        <div className="termin__items">
          <div className="termin__formAndIcon">
            <div className="iframe">
              <iframe
                src={`https://calendar.google.com/calendar/appointments/schedules/AcZssZ3MPPGcgIdQ34Vtg_41kNI2OqExuYAGarotSA5XpqeIBQ1aN71WiKYxvO1cOiGPs3MCtapzNz2I?gv=true&hl=${
                  isEnglish ? "en" : "de"
                }`}
                style={{
                  backgroundColor: "white",
                  border: "5px solid transparent",
                  borderRadius: "10px",
                  width: "100%",
                  height: "100%",
                }}
                title="Google Calendar"
              />
            </div>
            <div className="termin_rightSide">
              <div className="termin__items__text">
                <h1 className="rightSide_headerText">
                  {isEnglish
                    ? "Schedule a 30-minute demo"
                    : " Vereinbaren Sie eine 30-minütige Demo"}
                </h1>
              </div>
              <img
                src={terminIcon}
                className="termin_rightSideImg"
                alt="Icon"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer isEnglish={isEnglish} />
    </div>
  );
};

export default Termin;

import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./news.css";
import NavBarDesktop from "../navbarNews/navbarNewsDesktop";
import Footer from "../footer/footer";
import Arrow from "../../assets/arrow-right.svg";

const News = ({ isEnglish, setIsEnglish }) => {
  const [newsItems, setNewsItems] = useState([]);
  const [itemsToShow, setItemsToShow] = useState(5);
  const navigate = useNavigate();
  const BASE_URL = "https://oneri.tframe.de";
  const gridRef = useRef();

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/api/news?populate=headerImage,contentImage`,
          {
            headers: {
              Authorization:
                "Bearer bc5cb0b519649befe8d38eaa1e360c6b3c16ee7997c7fc74612bf2769f2ee0772ad5d1e93a281eba7a4e88d02f4fc87a39fccc8fda917a19f2f14b8663d0ccb99bad842d62c8c9771dd953af024fe0b5882108e22ba14207d5b8950ce0dd2481c092e5f32586f79f88d317607a1c0dafa2ae361494d7ad90ac0419b5c1dff9e6",
            },
          }
        );
        const data = await response.json();
        const sortedNewsItems = data.data.sort((a, b) => {
          return (
            new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt)
          );
        });
        setNewsItems(sortedNewsItems);
      } catch (error) {
        console.error("Error fetching news data:", error);
      }
    };

    fetchNews();
  }, []);

  useEffect(() => {
    if (gridRef.current) {
      const items = gridRef.current.children;
      for (let item of items) {
        const height = item.getBoundingClientRect().height;
        const rows = Math.ceil(height / 10);
        item.style.setProperty("--grid-row-end", rows);
      }
    }
  }, [newsItems, itemsToShow]);

  const toggleItems = () => {
    setItemsToShow((prev) =>
      prev === newsItems.length ? 5 : newsItems.length
    );
  };

  const handleInsideNews = (item) => {
    const slug = item.attributes.slug;
    navigate(`/news/${slug}`, { state: { item } });
    window.scrollTo(0, 0);
  };

  return (
    <div className="container">
      <NavBarDesktop
        direction={true}
        isEnglish={isEnglish}
        setIsEnglish={setIsEnglish}
      />
      <div className="newsBody">
        <h1 className="newsTitle">
          {isEnglish ? "News" : "Nachricht"}
          <span className="dot">.</span>
        </h1>
        <p className="newsText">
          {isEnglish
            ? "Stay updated with the latest Oneri news."
            : "Bleiben Sie mit den neuesten Oneri-Nachrichten auf dem Laufenden."}
        </p>
      </div>
      <div className="newsBody2">
        <h1>{isEnglish ? "Current Articles" : "Aktuelle Artikel"}</h1>
      </div>
      <div className="newsGrid" ref={gridRef}>
        {newsItems.slice(0, itemsToShow).map((item) => {
          const headerImage = item.attributes.headerImage?.data?.attributes?.url
            ? `${BASE_URL}${item.attributes.headerImage.data.attributes.url}`
            : null;
          const title = isEnglish
            ? item.attributes.title
            : item.attributes.titleDE;
          return (
            <div key={item.id} className="newsItemContainer">
              <div className="newsItem" onClick={() => handleInsideNews(item)}>
                {headerImage && (
                  <img
                    src={headerImage}
                    alt={title}
                    className="newsImage"
                    loading="lazy"
                  />
                )}
                <div className="newsOverlay">
                  <h2 className="newsBoxText">{title}</h2>
                </div>
              </div>
              <div
                className="newsContinueButton"
                onClick={() => handleInsideNews(item)}
              >
                <h1 className="newsBoxButton">
                  {isEnglish ? "Continue reading" : "Weiter lesen"}
                </h1>
                <img src={Arrow} alt="Arrow" />
              </div>
            </div>
          );
        })}
      </div>
      {newsItems.length > 5 && (
        <div className="newsBody3">
          <button className="button" onClick={toggleItems}>
            {isEnglish
              ? itemsToShow === newsItems.length
                ? "See less"
                : "See more"
              : itemsToShow === newsItems.length
              ? "Weniger sehen"
              : "Mehr sehen"}
          </button>
        </div>
      )}
      <Footer isEnglish={isEnglish}/>
    </div>
  );
};

export default News;

import React from "react";

function Title({ header = "", paragraph = "" }) {
  return (
    <div className="class__title">
      <h1 className="about__header">
        {header}
        <span className="about__dot">.</span>
      </h1>
      {/* <p className='about__paragraph'>{paragraph}</p> */}
    </div>
  );
}

export default Title;

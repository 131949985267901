import "./index.css";

const Button = ({
  title,
  classes,
  type,
  onClick,
  alt,
  disabled,
  isButtonDisabled,
}) => {
  return (
    <button
      onClick={onClick}
      className={`${classes}`}
      type={type}
      alt={alt}
      disabled={disabled || isButtonDisabled}
    >
      {title}
    </button>
  );
};

export default Button;

import React from "react";
import { useState ,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import oneriLogo from "../../assets/oneri_logo.png";
import mobilex from "../../assets/mobile-x.svg";
import Sidebar from "../../components/sidebar/Sidebar";
import burger from "../../assets/burgermenu.svg";
import Navbar from "../navbarNews/navbarNews";
import "./Impressum.css";
import Footer from "../footer/footer";
import { useLocation } from 'react-router-dom';

const Impressum = ({ isEnglish, setIsEnglish }) => {
  
  const location = useLocation();
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className="impressum__header">
      <div className={`mobile__menu ${showMenu ? "mobile__show-nav" : ""}`}>
        <img
          style={{ cursor: "pointer", marginTop: "30px", marginLeft: "40px" }}
          src={mobilex}
          alt=""
          onClick={() => setShowMenu(!showMenu)}
        ></img>
        <Sidebar
          show={showMenu}
          setShow={setShowMenu}
          setIsEnglish={setIsEnglish}
          isEnglish={isEnglish}
        />
      </div>
      <div className="stickyheader">
        <header className="header">
          <div className="header__logo" onClick={() => navigate("/")}>
            <img src={oneriLogo} alt="" />
          </div>
          <Navbar
            direction={true}
            isEnglish={isEnglish}
            setIsEnglish={setIsEnglish}
          />
        </header>
        <header className="header__mobile">
          <img onClick={() => setShowMenu(!showMenu)} src={burger} alt="" />
          <div className="header__logo" onClick={() => navigate("/")}>
            <img src={oneriLogo} alt="" />
          </div>
        </header>
      </div>

      {isEnglish ? (
        <div class="impressum__contanier">
          <h2 className="impressum__title">Imprint</h2>

          <p className="impressum__subtitle">Information according to §5 TMG</p>

          <div className="impressum__contentText">
            <p>TECH FRAME Deutschland GmbH</p>
            <p>Höhenrainer Str. 5, 81477,</p>
            <p>Munich, Germany</p>
            <p>Commercial Register: HRB 271429</p>
            <p>Register Court: Amtsgericht München</p>
          </div>

          <p className="impressum__subtitle">Contact</p>
          <p className="impressum__content">
            Email: <a href="mailto:info@oneri.de">info@oneri.de</a> 
          </p>

          <p className="impressum__subtitle">Managing Director</p>
          <p className="impressum__content">
            Gezim Begolli
          </p>

          <p className="impressum__subtitle">VAT ID</p>
          <p className="impressum__content">
            VAT ID in accordance with §27a of the Value Added Tax Act: DE 349279205
          </p>

          <p className="impressum__subtitle">EU Dispute Resolution</p>
          <p className="impressum__content">
            The European Commission provides a platform for online dispute
            resolution (OS):
            <a href="https://ec.europa.eu/consumers/odr" target="_blank">
              https://ec.europa.eu/consumers/odr
            </a>
            .
          </p>

          <p className="impressum__subtitle">
            You can find our email address above in the imprint.
          </p>

          <p className="impressum__content">
            Consumer Dispute Resolution/Universal Arbitration Board We are
            neither willing nor obliged to participate in dispute resolution
            proceedings before a consumer arbitration board.
          </p>
        </div>
      ) : (
        <div class="impressum__contanier">
          <h2 className="impressum__title">Impressum</h2>

          <p className="impressum__subtitle">Angaben gemäß §5 TMG</p>

          <div className="impressum__contentText">
            <p>TECH FRAME Deutschland GmbH</p>
            <p>Höhenrainer Str. 5, 81477,</p>
            <p>München, Germany</p>
            <p>Handelsregister: HRB 271429</p>
            <p>Registergericht: Amtsgericht München</p>
          </div>

          <p className="impressum__subtitle">Kontakt</p>
          <p className="impressum__content">
            E-Mail: <a href="mailto:info@oneri.de">info@oneri.de</a>{" "}
          </p>

          <p className="impressum__subtitle">Geschäftsführer</p>
          <p className="impressum__content">
            Gezim Begolli
          </p>

          <p className="impressum__subtitle">Umsatzsteuer-ID</p>
          <p className="impressum__content">
            Umsatzsteuer-ID gemäß §27 a Umsatzsteuergesetz: DE 349279205
          </p>

          <p className="impressum__subtitle">EU-Streitschlichtung</p>
          <p className="impressum__content">
            Die Europäische Kommission stellt eine Plattform zur
            Online-Streitbelegung (OS):
            <a href="https://ec.europa.eu/consumers/odr" target="_blank">
              https://ec.europa.eu/consumers/odr
            </a>
            .
          </p>

          <p className="impressum__subtitle">
            Unsere E-Mail-Adresse finden Sie oben im Impressum.
          </p>

          <p className="impressum__content">
            Verbraucherstreitbeilegung/Universalschlichtungsstelle Wir sind
            nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor
            einer Verbraucherschlichtungsstelle teilzunehmen.
          </p>
        </div>
      )}
       <div className="body">
         <Footer isEnglish={isEnglish}/>
        </div>
    </div>

  );
};

export default Impressum;
/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

const PdfComponent = () => {
  const pdfUrl = "https://oneri-pdf.s3.eu-central-1.amazonaws.com/ONERI-DE.pdf";
  const pdfUrlMobile =
    "https://drive.google.com/file/d/1zIICM8BlV6kYNPcV21zJvXFT9rI1R2SB/preview";

  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  return (
    <div className="pdf-component">
      {isMobile ? (
        <iframe src={pdfUrlMobile} frameborder="0"></iframe>
      ) : (
        <iframe src={pdfUrl} frameborder="0"></iframe>
      )}
    </div>
  );
};

export default PdfComponent;

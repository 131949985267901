import React from "react";
import ProgressiveImage from "react-progressive-image";
import "./index.css";

const ProgressiveImageWrapper = (props) => {
  const {lowImage,largerImage, handleImageClick = () => {}, alt = "", className = "progressive-example-image-last",} = props;

  return (
    <div className="progressive-image">
      <ProgressiveImage src={largerImage} placeholder={lowImage}>
        {(src, loading) => (
          <img
            alt={alt}
            src={loading ? lowImage : largerImage}
            className={className}
            onClick={() => handleImageClick(largerImage)}
            style={{ opacity: loading ? 0.9 : 1 }}
          />
        )}
      </ProgressiveImage>
    </div>
  );
};

export default ProgressiveImageWrapper;

import logo01 from "../assets/smartphone.svg";
import onesvg from "../assets/onesvg.svg";
import twosvg from "../assets/twosvg.svg";
import threesvg from "../assets/threesvg.svg";
import monitor from "../assets/monitor.svg";

export const cardsArray = [
  {
    title: "Front Office",
    subtitle: "Virtual or Direct sales",
    icon: logo01,
  },
  {
    title: "Back Office",
    subtitle: "Back Office",
    icon: monitor,
  },
];

export const frameArray = [
  {
    image: onesvg,
    header: "55,000+",
    title: "Verträge",
  },
  {
    image: twosvg,
    header: "30+",
    title: "Agentur",
  },
  {
    image: threesvg,
    header: "450+",
    title: "Abrechnung",
  },
];

export const frameArrayEn = [
  {
    image: onesvg,
    header: "55,000+",
    title: "Contracts",
  },
  {
    image: twosvg,
    header: "30+",
    title: "Agency",
  },
  {
    image: threesvg,
    header: "450+",
    title: "Settlement",
  },
];

import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import closeLogo from "../../assets/closeicon.svg";
import { render } from "@react-email/render";
import AWS from "aws-sdk";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Template from "./Template";
import dayjs from "dayjs";

AWS.config.update({
  region: process.env.REACT_APP_AWS_SES_REGION,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID
});
const ContactForm = ({
                       // userQuantity,
                       // ordersQuantity,
                       // closeModal,
                       // subscription,
                       isEnglish
                     }) => {
  // const [subsciption, setSubsciption] = useState(subscription);
  const [formValues, setFormValues] = useState({
    user_name: "",
    user_email: "",
    user_enterprise: "",
    user_emloyeeNumber: "",
    user_message: "",
    user_language: "",
    user_selectedDate: dayjs().format("MM-DD-YYYY")
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const form = useRef();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value
    }));
  };

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date).format("MM-DD-YYYY");
    setFormValues({
      ...formValues,
      user_selectedDate: formattedDate
    });
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    if (
      !formValues.user_email
    ) {
      setError(
        isEnglish
          ? "Please fill in all required fields"
          : "Bitte füllen Sie alle geforderten Felder aus"
      );
      return;
    }
    const emailHtml = render(<Template data={formValues} />);
    const options = {
      Source: "info@oneri.de",
      Destination: {
        ToAddresses: ["rinor@oneri.de", "Rinor.hatashi@tframe.de"],
        CcAddresses: ["j.schaarschmidt@oneri.de"]
      },
      Message: {
        Body: {
          Html: {
            Charset: "UTF-8",
            Data: emailHtml
          }
        },
        Subject: {
          Charset: "UTF-8",
          Data: "Contact Oneri"
        }
      }
    };
    await new AWS.SES({ apiVersion: "2010-12-01" })
      .sendEmail(options)
      .promise();
    form.current?.reset();
    toast(
      isEnglish
        ? "Thank you for contacting us. We will reach you shortly!"
        : "Danke, dass Sie uns kontaktiert haben. Wir werden Sie in Kürze erreichen!"
    );
  };

  const textEnglish = "Send";
  const textGerman = "Schicken";
  const loadingEnglish = "Loading...";
  const loadingGerman = "Laden...";
  const errorEnglish = "Please fill in all required fields";
  const errorGerman = "Bitte füllen Sie alle geforderten Felder aus";

  return (
    <div className="contactForm-contanier">
      <form
        ref={form}
        onSubmit={sendEmail}
        className="parent-box-formbox__container"
      >

        <div
          className="parent-box-formbox"
        >
          <div className="parent-box-formbox-input-wrapper">
            <div className="parent-box-formbox-input-name">
              <label className="parent-box-formbox-label">Name</label>
              <div className="parent-box-formbox-input-container">
                <input
                  className="parent-formbox-inputform"
                  type="text"
                  placeholder="Name"
                  name="user_name"
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className="parent-box-formbox-input-wrapper">
            <div className="parent-box-formbox-input-big">
              <label className="parent-box-formbox-label">
                {isEnglish ? "Number of employees" : "Anzahl der Angestellten"}
              </label>
              <div className="parent-box-formbox-input-container">
                <input
                  className="parent-formbox-inputform"
                  type="text"
                  name="user_emloyeeNumber"
                  placeholder={
                    isEnglish
                      ? "Number of employees"
                      : "Anzahl der Angestellten"
                  }
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className="parent-box-formbox-input-container--datePicker">
            <div className="parent-box-formbox-label">
              {isEnglish ? "Date" : "Datum"}
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs} >
              <DatePicker
               sx={{
                backgroundColor: "white",
                borderRadius:3,
               }}
                name="user_selectedDate"
                onChange={handleDateChange}
                value={dayjs(formValues.user_selectedDate)}
              />
            </LocalizationProvider>
          </div>
          <div className="parent-box-formbox-input-big">
            <label className="parent-box-formbox-label">
              {isEnglish ? "Demo Language" : "Demosprache"}
            </label>
            <div className="inputForm__radioButtons">
              <input
                className="rinputForm__radioInput"
                type="radio"
                name="user_language"
                value="en"
                checked={formValues.user_language === "en"}
                onChange={handleInputChange}
              />
              EN
            </div>
            <div className="inputForm__radioButtons">
              <input
                className="rinputForm__radioInput"
                type="radio"
                name="user_language"
                value="de"
                checked={formValues.user_language === "de"}
                onChange={handleInputChange}
              />
              DE
            </div>
          </div>
        </div>

        <div className="parent-box-formbox">
          <div className="parent-box-formbox-company-input">
            <label className="parent-box-formbox-label">
              {isEnglish ? "Company" : "Unternehmen"}
            </label>
            <div className="parent-box-formbox-input-container">
              <input
                className="parent-formbox-inputform"
                type="text"
                name="user_enterprise"
                placeholder={isEnglish ? "Company" : "Unternehmen"}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="parent-box-formbox-input-big--email">
            <label className="parent-box-formbox-label--email">
              {isEnglish ? "Email" : "E-mail"}
            </label>
            <div className="parent-box-formbox-input-container--email">
              <input
                className="parent-formbox-inputform"
                type="email"
                name="user_email"
                placeholder={isEnglish ? "Email" : "E-Mail"}
                onChange={handleInputChange}
                required
              />
            </div>
          </div>

          <div className="parent-box-formbox-input-wrapper">
            <div className="parent-box-formbox-input-big">
              <label className="parent-box-formbox-label">
                {isEnglish ? "Description" : "Beschreibung"}
              </label>
              <div className="parent-box-formbox-input-container">
                <textarea
                  className="parent-formbox-inputform-big"
                  type="text"
                  placeholder={isEnglish ? "Description" : "Beschreibung"}
                  name="user_message"
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className="parent-box-formbox-input-big">
            <button className="parent-formbox-inputform-button" type="submit">
              {isLoading
                ? isEnglish
                ? loadingEnglish
                : loadingGerman
                : isEnglish
                ? textEnglish
                : textGerman}
            </button>
          </div>
        </div>

      </form>
    </div>
  );
};
export default ContactForm;
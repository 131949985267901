import React from "react";

const SingleBreadCrumb = ({ name, active, showSlash }) => {
  return (
    <div
      className={`SingleBreadCrumb ${active ? "SingleBreadCrumb-active" : ""}`}
    >
      <p>{name}</p>
      {showSlash && <span>/</span>}
    </div>
  );
};

export default SingleBreadCrumb;

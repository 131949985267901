import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../pages/testimonials/testimonials.css";
import avatar1 from "../../assets/klaus.jpeg";
import avatar2 from "../../assets/jens.jpeg";
import stars from "../../assets/stars.svg";
import { ReactComponent as RiArrowRightSLine } from "../../assets/arrowLeft.svg";
import { ReactComponent as RiArrowLeftSLine } from "../../assets/arrowRight.svg";
import SliderBox from "./SliderBox";

// slide arrows
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <RiArrowRightSLine className="slide__icon" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <RiArrowLeftSLine className="slide__icon" />
    </div>
  );
}

const SimpleSlider = ({ isEnglish }) => {
  var settings = {
    className: "testimional-slider",
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SamplePrevArrow />,
    prevArrow: <SampleNextArrow />,
    centerMode: true, //set this to false, when u want to show 3 cards, and go to css in testimonial.css uncoment the line of margin with class .testimonials__single-card
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  const testimonialsListGer = [
    {
      name: "Klaus Brehm",
      company: "MAXprom",
      img: avatar1,
      icon: stars,
      text: "Unsere Verkäufe sind gerade durch die Decke gegangen, weil wir mehr Verträge auf einmal abschließen könnten, anstatt nur einen Vertrag auf einmal, insbesondere mit der Offline-Vertragsfunktion, um mehr Gebiete ohne Internetabdeckung für Verkäufe zu erreichen.",
    },
    {
      name: "Jens R. Schaarschmidt",
      company: "MAXprom",
      img: avatar2,
      icon: stars,
      text: "Dieses Power Paket ONERI setzt absolut Maßsstäbe in jeder Hinsicht. Meine absolute Empfehlung! 5 Sterne",
    },
  ];
  const testimonialsListEn = [
    {
      name: "Klaus Brehm",
      company: "MAXprom",
      img: avatar1,
      icon: stars,
      text: "Our sales just went through the roof because we could close more contracts at a time instead of just one contract at a time, especially with the offline contract feature to reach more areas without internet coverage for sales.",
    },
    {
      name: "Jens R. Schaarschmidt",
      company: "MAXprom",
      img: avatar2,
      icon: stars,
      text: "This power package ONERI sets absolute standards in every respect. My absolute recommendation! 5 Stars",
    },
  ];

  return (
    <div className="container testimional-container">
      <Slider {...settings}>
        {isEnglish
          ? testimonialsListEn.map((element, index) => (
              <SliderBox
                key={index}
                icon={element.icon}
                img={element.img}
                name={element.name}
                text={element.text}
                company={element.company}
              />
            ))
          : testimonialsListGer.map((element, index) => (
              <SliderBox
                key={index}
                icon={element.icon}
                img={element.img}
                name={element.name}
                text={element.text}
                company={element.company}
              />
            ))}
      </Slider>
    </div>
  );
};

export default SimpleSlider;

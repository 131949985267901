import Tooltip from "@material-ui/core/Tooltip";
import {withStyles} from "@material-ui/core/styles";

const HtmlTooltip = withStyles( theme => ({
    tooltip: {
        backgroundColor: "white",
        color: "#000",
        padding: 0,
        fontSize: 12,
        borderRadius: 5,
        border: "1px solid #6C1EB0",
        position:'relative',
        width:290,
        top:7
    },
    arrow: {
      "&::before": {
        backgroundColor: "white",
        border: "1px solid #6C1EB0",
        position:'absolute',
        top: -2,
        left:-2
      }
    },
}))(Tooltip);

export default HtmlTooltip;
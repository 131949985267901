import React, { useState } from "react";
import "./Dropdown.css";
import DropdownComponent from "./DropdownComponent";
import { featureItems } from "./features";

const Dropdown = ({ paramId, isEnglish }) => {
  const [isActive, setIsActive] = useState({ name: "Einleitung" });
  return (
    <>
      <div className="dropdown-primary">
        {featureItems?.map((item) => {
          return (
            <DropdownComponent
              setIsActive={() => setIsActive({ name: item.name })}
              name={isEnglish ? item?.nameEn : item?.name}
              link={item?.link}
              options={isEnglish ? item?.optionEn : item?.option}
              isActive={isActive.name === item.name}
              paramId={paramId}
            />
          );
        })}
      </div>
    </>
  );
};

export default Dropdown;

import React, { useState } from "react";
import "./index.css";
import module from "../../assets/oneriModule.png";
import moduleEn from "../../assets/oneriModuleEn.png";
import moduleRight from "../../assets/moduleOneriRight.png";
import tick from "../../assets/tick.svg";

function Module({ isEnglish }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const expandableTextClass = isExpanded
    ? "expandable-text expandable-text-lin7 show-more"
    : "expandable-text expandable-text-lin7";
  return (
    <div className="module__container">
      <div className="module__left">
        {isEnglish ? (
          <img src={moduleEn} alt="" />
        ) : (
          <img src={module} alt="" />
        )}
      </div>
      <div className="module__right">
        <div className="module__right__up">
          <div className="module__right__up__image">
            <img src={moduleRight} alt="" className="module__right__up__img" />
          </div>
          {isEnglish ? (
            <div className="module__textAndTick">
              <span>
                By utilizing the various modules offered by ONERI, businesses
                can fully digitize and digitally manage all sales activities and
                sales performance. By setting up incentives and team provisions,
                adding contracts and leads/contacts to the system, and creating
                user accounts, ONERI can be up and running.
              </span>
              <span>
                The system allows you to create multiple teams and clients, with
                the ability to set up multi-level bonuses that can be
                automatically calculated using the settlement module down to the
                smallest detail.
              </span>
            </div>
          ) : (
            <div className="module__textAndTick">
              <span>
                ONERI MODULE
                <br></br>
                Durch die Nutzung verschiedener Module, welche in
                der ONERI-Suite integriert sind, können Unternehmen alle
                Vertriebsprozesse und die Vertriebsperformance vollständig
                digitalisieren und digital verwalten. Nach dem Einrichten von
              </span>
              <div className="module__left__mobile">
                <img src={module} alt="" />
              </div>
              <div className="modules_ticksText">
                <div className="modulesTick">
                  <img src={tick} alt="" className="mod__tick" />
                  <span>Nutzergruppen</span>
                </div>
                <div className="modulesTick">
                  <img src={tick} alt="" className="mod__tick" />
                  <span>Produkten- und Tarifen</span>
                </div>
                <div className="modulesTick">
                  <img src={tick} alt="" className="mod__tick" />
                  <span>Bonus- und Vergütungsmatrix</span>
                </div>
                <div className="modulesTick">
                  <img src={tick} alt="" className="mod__tick" />
                  <span>Kunden- und Leadlisten</span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={`module__right__down ${expandableTextClass}`}>
          {isEnglish ? (
            <span>
              Once set up, businesses can generate various reports and track
              day-to-day activities, communicate with teams across different
              locations, and provide training materials through the Academy
              modules. Overall, ONERI offers a comprehensive solution for
              digitizing and streamlining sales operations.
            </span>
          ) : (
            <span>
              ist ONERI sofort einsatzfähig. ONERI ist mandantenfähig und
              ermöglicht die Verwaltung von mehreren Vertriebsteams und
              -organisationen. ONERI ermöglicht eine automatisierte Abrechnung
              von mehrstufigen Bonus- und Vergütungssystemen nach Ihren
              Vorgaben. ONERI bietet eine umfassendes, graphisch aufbereitetes
              Management-Reporting nach Ihren Vorgaben. Der MESSENGER gestattet
              die standortunabhängige Kommunikation in Echtzeit zu und zwischen
              Vertriebsmitarbeitern. Notwendige Schulungs- und
              Präsentationsunterlagen können den Vertriebsmitarbeitern über die
              ONERI-ACADEMY jederzeit zur Verfügung gestellt werden. ONERI ist
              die vollumfassende Lösung für den erfolgreichen Aufbau eines
              papierlosen Vertriebsprozesses im Direktvertrieb.
            </span>
          )}
        </div>
        <div onClick={handleToggle} className="showMoreButton front">
          {isEnglish ? (
            <span>{isExpanded ? "Show less" : "Show more"}</span>
          ) : (
            <span>{isExpanded ? "Weniger anzeigen" : "Mehr anzeigen"}</span>
          )}
        </div>
      </div>
    </div>
  );
}

export default Module;

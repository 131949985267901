import React from "react";

function VideoHeader() {
  return (
    <div className="popup-parent-video">
      <iframe
        width="100%"
        height="100%"
        className="popup-parent-frame"
        src="https://www.youtube.com/embed/Qd11MOqQ3mo?si=kaTnWGFdabWHntDD&controls=0&autoplay=1&mute=1&loop=1&playlist=Qd11MOqQ3mo&rel=0"
        title="ONERI"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default VideoHeader;

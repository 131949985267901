import React, { useState } from "react";
import "./WebFeatures.css";
import tick from "../../assets/tick.svg";
import chart from "../../assets/frontChart.svg";
import chartEn from "../../assets/frontChartEn.svg";

const MobileApp = ({ isEnglish }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const expandableTextClass = isExpanded
    ? "expandable-text expandable-text-lin7 show-more"
    : "expandable-text expandable-text-lin7";
  return (
    <div className="section__two--content">
      <div className={`section__two--text ${expandableTextClass}`}>
        <span className="section__two--text-h1">Front Office</span>
        {isEnglish ? (
          <div className="section__two--text-p_1">
            <p>
              ONERI enables sales teams to conveniently manage all their direct
              sales operations using their mobile and tablet devices, including
              tasks such as time attendance, accessing lead and client
              information, composing contracts, and obtaining digital
              signatures. ONERI also has the added advantage of being able to
              work offline in areas where internet connectivity is limited.
              Sales teams members can monitor their activities in real-time and
              assess their performance against other team members using ONERI.
              They can also view their potential earnings on a monthly basis,
              which are automatically calculated and distributed once contracts
              have been reviewed and approved.Overall, ONERI offers an efficient
              and streamlined solution for sales teams to manage their direct
              sales operations, allowing them to work with greater effectiveness
              and accuracy.
            </p>
          </div>
        ) : (
          <div className="section__two--text-p_1">
            <p>
              Mit ONERI können Vertriebsteams und -mitarbeiter alle Vorgänge
              über ihre Smart-Phones oder Tabletseräte verwalten bzw. erfassen.
              Die Funktionalitäten umfassen unter anderem:
            </p>
          </div>
        )}
        {isEnglish ? (
          ""
        ) : (
          <div>
            <div className="ticks">
              <div className="tick">
                <img src={tick} alt="" />
                Die detaillierte Zeit- und Ergebniserfassung bei Kundenbesuchen
              </div>
              <div className="tick">
                <img src={tick} alt="" />
                Die Dokumentation von Arbeitszeiten
              </div>
              <div className="tick">
                <img src={tick} alt="" />
                Zugriff und Verwaltung von Lead- und Kundenlisten
              </div>
              <div className="tick">
                <img src={tick} alt="" />
                Erfassung von Aufträgen und Einholen digitaler Unterschriften
                beim Kunden
              </div>
            </div>
            <div className="section__two--text-p_1 mobile">
              <p>
                Die ONERI-Frontoffice-App funktioniert auch offline. Sie bietet
                alle Funktionen auch in Regionen mit eingeschränkter
                Internetverbindung. Vertriebsmitarbeiter können ihre Aktivitäten
                in Echtzeit überwachen und ihre Vertriebsleistung mit anderen
                Teammitgliedern teilen. Sie können jederzeit in Echtzeit ihr zu
                erwartendes Monatseinkommen auf Basis der aktuellen
                Vertriebsleistung einsehen.
              </p>
            </div>
            <div className="section__two--text-p_1 mobile">
              <p>
                ONERI ist die perfekte Lösung zur Verwaltung, Überwachung und
                Steuerung dezentraler Direktvertriebsaktivitäten.
              </p>
            </div>
          </div>
        )}
      </div>
      <div onClick={handleToggle} className="showMoreButton front">
        {isEnglish ? (
          <span>{isExpanded ? "Show less" : "Show more"}</span>
        ) : (
          <span>{isExpanded ? "Weniger anzeigen" : "Mehr anzeigen"}</span>
        )}
      </div>
      <div className="section__two--chart">
        {isEnglish ? (
          <img src={chartEn} alt="" className="section__two--chart-img" />
        ) : (
          <img src={chart} alt="" className="section__two--chart-img" />
        )}
      </div>
    </div>
  );
};

export default MobileApp;

import React, { useState } from "react";
import "./toggleButton.css";
import UkFlag from "../../assets/english-flag.svg";
import DeFlag from "../../assets/deutsche-flag.svg";

const ToggleButton = ({ setIsEnglish }) => {
  const [value, setValue] = useState(localStorage.getItem("languageVal") || "de");

  const toggleClick = () => {
    setTimeout(() => {
      if (value === "de") {
        setValue("en");
        localStorage.setItem("languageVal", "en");
        setIsEnglish(true);
      } else {
        setValue("de");
        setIsEnglish(false);
        localStorage.setItem("languageVal", "de");
      }
    }, 0);
  };

  return (
    <div className="form">
    <div className="form-image">
    <img
        onClick={toggleClick}
        className={`swiping-img-en ${value === "de" ? 'front' : 'back'}`}
        src={DeFlag}
        alt=""
      />
      <img
        onClick={toggleClick}
        src={UkFlag}
        className={`swiping-img-de ${value === "en" ? "front" : "back"}`}
        alt=""
      />
    </div>
  </div>
    
  );
};

export default ToggleButton;


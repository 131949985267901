import React, { useState } from "react";
import Navbar from "../navBar/navBar";
import Subscription from "../subscription";
import { useNavigate } from "react-router";
import burger from "../../assets/burgermenu.svg";
import oneriLogo from "../../assets/oneri_logo.png";
import mobilex from "../../assets/mobile-x.svg";
import Sidebar from "../../components/sidebar/Sidebar";
import Footer from "../footer/footer";
import "./calculator.css";

function Calculator({
  show = false,
  setShow,
  setIsEnglish,
  isEnglish,
  // setShowMenu,
  // showMenu,
}) {
  const [showMenu, setShowMenu] = useState(false);
  const [userQuantity, setUserQuantity] = useState(5);
  const [ordersQuantity, setOrdersQuantity] = useState(100);
  const navigate = useNavigate();
  return (
    <div className="feature-navbar_header">
      <div className={`mobile__menu ${showMenu ? "mobile__show-nav" : ""}`}>
        <img
          style={{ cursor: "pointer", marginTop: "30px", marginLeft: "40px" }}
          src={mobilex}
          alt=""
          onClick={() => setShowMenu(!showMenu)}
        ></img>
        <Sidebar
          show={showMenu}
          setShow={setShowMenu}
          setIsEnglish={setIsEnglish}
          isEnglish={isEnglish}
        />
      </div>
      <div className="stickyheader">
        <header className="header">
          <div className="header__logo" onClick={() => navigate("/")}>
            <img src={oneriLogo} alt="" />
          </div>
          <Navbar
            direction={true}
            isEnglish={isEnglish}
            setIsEnglish={setIsEnglish}
          />
        </header>
        <header className="header__mobile">
          <img onClick={() => setShowMenu(!showMenu)} src={burger} alt=""></img>
          <div className="header__logo" onClick={() => navigate("/")}>
            <img src={oneriLogo} alt="" />
          </div>
        </header>
      </div>
      <div>
        <Subscription
          userQuantity={userQuantity}
          setUserQuantity={setUserQuantity}
          ordersQuantity={ordersQuantity}
          setOrdersQuantity={setOrdersQuantity}
        />
      </div>
      <div className="calculator__footer">
        <Footer
          isEnglish={isEnglish}
          className="calculator__footer__component"
        />
      </div>
    </div>
  );
}

export default Calculator;

import React, { useState } from "react";
import burger from "../../assets/burgermenu.svg";
import oneriLogo from "../../assets/oneri_logo.png";
import mobilex from "../../assets/mobile-x.svg";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../navbarNews/navbarNews";
import { useNavigate } from "react-router";
import { buttons } from "../qrVideoPage/buttonsJson";
import "./index.css";
import qrEn from "../../assets/qrEn.svg";
import qrDe from "../../assets/qrDe.svg";

function QrVideoComponent({ isEnglish, setIsEnglish }) {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <div className={`mobile__menu ${showMenu ? "mobile__show-nav" : ""}`}>
        <img
          style={{ cursor: "pointer", marginTop: "30px", marginLeft: "40px" }}
          src={mobilex}
          alt=""
          onClick={() => setShowMenu(!showMenu)}
        ></img>
        <Sidebar
          show={showMenu}
          setShow={setShowMenu}
          setIsEnglish={setIsEnglish}
          isEnglish={isEnglish}
        />
      </div>
      <div className="stickyheader">
        <header className="header">
          <div className="header__logo" onClick={() => navigate("/")}>
            <img src={oneriLogo} alt="" />
          </div>
          <Navbar
            direction={true}
            isEnglish={isEnglish}
            setIsEnglish={setIsEnglish}
          />
        </header>
        <header className="header__mobile">
          <img onClick={() => setShowMenu(!showMenu)} src={burger} alt=""></img>
          <div className="header__logo" onClick={() => navigate("/")}>
            <img src={oneriLogo} alt="" />
          </div>
        </header>
      </div>
      <div className="qrCodeVideo__container">
        <div className="blur-bg"></div>
        {/* <img className="background__img" src={qrvideoBackground} alt="" /> */}
        <div className="qrCodeVideo__videoContent__parent">
          <div className="qrCodeVideo__videoContent">
            <div className="video">
              <div className="video_style">
                <iframe
                  width="100%"
                  height="300"
                  src="https://www.youtube.com/embed/Qd11MOqQ3mo?controls=0&autoplay=1&mute=1"
                  title="The Blaze live at Aiguille du Midi in Chamonix, France for Cercle"
                  allow="autoplay; encrypted-media; accelerometer; clipboard-write; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
          <div className="qr__links">
            {buttons?.map((items) => {
              return (
                <div className="buttons__container">
                  <div
                    onClick={() => (window.location.href = items.url)}
                    className="buttons__style"
                  >
                    <div className="buttons__style__child">
                      <img src={items.image} alt="" />
                      <span className="buttons__name">{items.name}</span>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="buttons__container">
              <div className="buttons__style catalog">
                <div className="buttons__style__child">
                  <span className="buttons__name">CATALOG</span>
                  <img
                    onClick={() =>
                      window.open("https://oneri.de/Oneri.pdf", "_blank")
                    }
                    src={qrDe}
                    alt=""
                  />
                  <img
                    onClick={() =>
                      window.open("https://oneri.de/Oneri-en.pdf", "_blank")
                    }
                    src={qrEn}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default QrVideoComponent;

const Template = ({ data }) => {
  return (
    <div>
      <p>Name: {data?.user_name}</p>
      <p>Email: {data?.user_email}</p>
      <p>Company Name: {data?.user_enterprise}</p>
      <p>Employee Number: {data?.user_emloyeeNumber}</p>
      <p>Language: {data?.user_language}</p>
      <p>Message: {data?.user_message}</p>
      <p>Date: {data?.user_selectedDate}</p>
    </div>
  );
};
export default Template;

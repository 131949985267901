import One from "../../assets/campaign-frame1.svg";
import AlleBestellungen from "../../assets/image1000.png";
import LowAlleBestellungen from "../../assets/image1000_11.png";
import Two from "../../assets/campaign-frame2.svg";
import Vorschau from "../../assets/image2000.png";
import LowVorschau from "../../assets/image2000_11.png";
import Three from "../../assets/campaign-frame3.svg";
import Four from "../../assets/campaign-frame4.svg";
import Five from "../../assets/campaign-frame5.svg";
import Six from "../../assets/campaign-frame6.svg";
import ExampleThree from "../../assets/image3.png";
import LowExampleThree from "../../assets/image3_11.png";
import ExampleFour from "../../assets/image4.png";
import LowExampleFour from "../../assets/image4_11.png";
import Seven from "../../assets/campaign-frame7.svg";
import Eight from "../../assets/campaign-frame8.svg";
import ExampleFive from "../../assets/image5.png";
import LowExampleFive from "../../assets/lowImage5_11zon.png";
import ExampleSix from "../../assets/image6.png";
import LowExampleSix from "../../assets/image6_11.png";
import Nine from "../../assets/campaign-frame9.svg";

export const contentData = [
    {
        title: 'New contracts',
        titleDe: 'Neu Verträge',
        titleImg: One,
        secondTitle: '',
        secondTitleDe: '',
        secondTitleImg: '',
        cardTitle: 'Contract Creation',
        card: `When a new contract is created by an agent, it's not just a document - it's an opportunity! 📄 Once the contract is created, it is pushed into our ONERI system in real time, so each contract is tracked, monitored and ready for the next step. No delays, no missing data.`,
        cardTitleDe: 'Vertragserstellung',
        cardDe: `Wenn ein neuer Vertrag von einem Agenten erstellt wird, ist es nicht nur ein Dokument – es ist eine Gelegenheit! 📄 Sobald der Vertrag erstellt wurde, wird er in Echtzeit in unser ONERI-System übertragen, sodass jeder Vertrag verfolgt, überwacht und für den nächsten Schritt bereit ist. Keine Verzögerungen, keine fehlenden Daten.`,
        lowImage: LowAlleBestellungen,
        largerImage: AlleBestellungen,
        singleLeft: true,
        singleRight: false,
        reverse: false
    },
    {
        title: 'Real time process',
        titleDe: 'Echtzeitprozess',
        titleImg: Two,
        secondTitle: '',
        secondTitleDe: '',
        secondTitleImg: '',
        cardTitle: 'Back office verification',
        card: `Each contract then undergoes a thorough review by the provider's back office team. 🛡 Here we ensure that everything is correct, complete and compliant. The goal? To ensure the highest quality and a smooth experience for customers and agents.`,
        cardTitleDe: 'Backoffice-Überprüfung',
        cardDe: `Jeder Vertrag macht anschließend eine gründliche Überprüfung durch das Backoffice-Team des Anbieters. 🛡 Hier stellen wir sicher, dass alles korrekt, vollständig und regelkonform ist. Das Ziel? Höchste Qualität zu gewährleisten und ein reibungsloses Erlebnis für Kunden und Agenten sicherzustellen.`,
        lowImage: LowVorschau,
        largerImage: Vorschau,
        singleLeft: false,
        singleRight: true,
        reverse: true
    }, 
    {
        title: 'User Controlled',
        titleDe: 'Benutzer Gesteuert',
        titleImg: Three,
        secondTitle: 'Order status',
        secondTitleDe: 'Bestellstatus',
        secondTitleImg: Four,
        cardTitle: 'Assignment of contract status',
        card: `Once reviewed, each contract is assigned a specific status based on its current status in the process. This means that the contract is either pending, approved, incomplete or ready to be submitted. This transparent status assignment helps teams set their priorities and concentrate on what is important.`,
        cardTitleDe: 'Vergabe des Vertragsstatus',
        cardDe: `Nach der Überprüfung wird jedem Vertrag ein bestimmter Status zugewiesen, basierend auf seinem aktuellen Stand im Prozess. Das bedeutet, dass der Vertrag entweder noch ausstehend, genehmigt, unvollständig oder bereit zur Übermittlung ist. Diese transparente Statusvergabe hilft den Teams, ihre Prioritäten zu setzen und sich auf das Wesentliche zu konzentrieren.`,
        lowImage: LowExampleThree,
        largerImage: ExampleThree,
        singleLeft: false,
        singleRight: false,
        reverse: false
    },
    {
        title: 'Customers confirm',
        titleDe: 'Kunden Bestätigen',
        titleImg: Five,
        secondTitle: 'Multiple files',
        secondTitleDe: 'Mehrere Dateien',
        secondTitleImg: Six,
        cardTitle: 'Forwarding to suppliers',
        card: `Once approved by the back office, the contracts are automatically forwarded to our partners who use the system. 🚚📲 This integration allows us to deliver great performance without any problems, while keeping everyone involved up to date.`,
        cardTitleDe: 'Weiterleitung an Lieferanten',
        cardDe: `Nach der Freigabe durch das Backoffice werden die Verträge automatisch an unsere Partner, die das System nutzen, weitergeleitet. 🚚📲 Diese Integration ermöglicht es uns, große Leistung problemlos zu erbringen, während alle Beteiligten stets auf dem neuesten Stand bleiben.`,
        lowImage: LowExampleFour,
        largerImage: ExampleFour,
        singleLeft: false,
        singleRight: false,
        reverse: true
    },
    {
        title: 'Calculate',
        titleDe: 'Berechnen',
        titleImg: Seven,
        secondTitle: 'Processing',
        secondTitleDe: 'Verarbeitung',
        secondTitleImg: Eight,
        cardTitle: 'Generation and commission accounting',
        card: `ONERI also allows the selection of multiple contracts for automated billing creation in the back office module. This means that with just a few clicks the system will show the exact earnings the agent charges based on their contract performance, score or volume - making payment processing faster, more accurate and less complicated.`,
        cardTitleDe: 'Generierung und Provisionsabrechnung',
        cardDe: `ONERI ermöglicht auch die Auswahl mehrerer Verträge zur automatisierten Abrechnungserstellung im Backoffice-Modul. Das bedeutet, dass mit nur wenigen Klicks das System die genauen Einnahmen der Agenten berechnet, basierend auf ihrer Vertragsleistung, Punktzahl oder dem Volumen – was die Zahlungsabwicklung schneller, präziser und unkomplizierter macht.`,
        lowImage: LowExampleFive,
        largerImage: ExampleFive,
        singleLeft: false,
        singleRight: false,
        reverse: false
    },
    {
        title: 'Credit',
        titleDe: 'Abrechnung',
        titleImg: Nine,
        secondTitle: '',
        secondTitleDe: '',
        secondTitleImg: '',
        cardTitle: '',
        card: `Through ONERI's end-to-end solution, we transform the entire contract lifecycle into an open, automated and transparent process that increases team productivity and ensures that every contract counts! 💼✨`,
        cardTitleDe: '',
        cardDe: `Durch die End-to-End-Lösung von ONERI verwandeln wir den gesamten Vertragslebenszyklus in einen offenen, automatisierten und transparenten Prozess, der sich durch die Produktivität der Teams steigert und sicher stellt, dass jeder Vertrag zählt! 💼✨`,
        lowImage: LowExampleSix,
        largerImage: ExampleSix,
        singleLeft: false,
        singleRight: true,
        reverse: false
    }
];
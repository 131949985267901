import React, { useEffect, useState } from "react";
import "./index.css";
import ProgressiveImageWrapper from "../progressiveImageWrapper";
import SalesTrackerCard from "../salesTrackerCard";

function getiOSVersion() {
  const userAgent = window.navigator.userAgent;

  const iOS = /iP(hone|od|ad)/.test(userAgent);

  if (iOS) {
    const match = userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (match) {
      const majorVersion = parseInt(match[1], 10);
      const minorVersion = parseInt(match[2], 10) || 0;
      const patchVersion = parseInt(match[3], 10) || 0;

      return [majorVersion, minorVersion, patchVersion];
    }
  }

  return null;
}

function isVersionLowerThan(version, targetVersion) {
  const [major, minor] = version;
  const [targetMajor, targetMinor] = targetVersion;

  if (major < targetMajor) {
    return true;
  } else if (major > targetMajor) {
    return false;
  }

  if (minor < targetMinor) {
    return true;
  } else if (minor > targetMinor) {
    return false;
  }

  return false;
}

const Section = ({ handleImageClick, isEnglish, section }) => {
  const [iosVerions, setIosVersion] = useState(null);

  useEffect(() => {
    const iOSVersion = getiOSVersion();
    if (iOSVersion) {
      const targetVersion = [18, 0];
      if (isVersionLowerThan(iOSVersion, targetVersion)) {
        setIosVersion(false);
      } else {
        setIosVersion(true);
      }
    } else {
      setIosVersion(null);
    }
  }, []);
  return (
    <div
      className={`section ${iosVerions ? "c-m0" : "c-m80"} ${
        iosVerions === null && "c-m0"
      }`}
    >
      <div className="section-title zoom-effect">
        <div
          className={`section-title-left ${
            section.singleLeft && "custom-width-left"
          } ${section.singleRight && "dn"}`}
        >
          <span className="title-text">
            {isEnglish ? section.title : section.titleDe}
          </span>
          <ProgressiveImageWrapper
            lowImage={section.titleImg}
            largerImage={section.titleImg}
          />
        </div>
        <div
          className={`section-title-right ${
            section.singleRight && "custom-width-right"
          } ${section.singleLeft && "dn"}`}
        >
          <img src={section.secondTitleImg || section.titleImg} alt="" />
          <span className="title-text">
            {isEnglish
              ? section.secondTitle || section.title
              : section.secondTitleDe || section.titleDe}
          </span>
        </div>
      </div>
      <div
        className={`section-content ${section.reverse && "section-reverse"}`}
      >
        <div className="section-content-left-card">
          <SalesTrackerCard isEnglish={isEnglish} section={section} />
        </div>
        <div className="section-content-middle-line scroll-container">
          <div className="scroll-line-container">
            <div className="scroll-line"></div>
            <div className="scroll-line-filled" id="scrollFill"></div>
          </div>
        </div>
        <div className="section-content-right-image">
          <ProgressiveImageWrapper
            handleImageClick={handleImageClick}
            lowImage={section.lowImage}
            largerImage={section.largerImage}
          />
        </div>
      </div>
    </div>
  );
};

export default Section;

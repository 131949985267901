import React from "react";
import { useNavigate } from "react-router";
import { ReactComponent as ArrowDown } from "../../assets/arrowDown.svg";

const DropdownComponent = (props) => {
  const navigate = useNavigate();
  const { isActive, options, name, setIsActive, paramId, image, link } = props;
  return (
    <div className="dropdown">
      <div
        className={`dropdown-btn ${isActive ? "active" : ""}`}
        onClick={
          link
            ? () => {
                navigate(`/modules/${link ? link : ""}`);
                setIsActive();
              }
            : setIsActive
        }
      >
        <div className={`dropdown-primary-hover`}></div>
        <div className="dropdown-btn-text">
          {name}
          <span>.</span>
        </div>
        {!link ? (
          <ArrowDown className={isActive ? "arrow" : "arrowClose"} />
        ) : (
          ""
        )}
      </div>
      <div className="dropdown-boarder"></div>
    </div>
  );
};

export default DropdownComponent;

import oneriLogo from "../assets/oneri_logo.png";
import landingPageDashboard from "../assets/landingPageDashboard.png";
import Button from "../components/button/index";
import Cards from "../components/cards";
import Frame from "../components/frame/index";
import Partners from "../pages/our partners/ourPartners";
import Testimonials from "./testimonials/testimonials";
import ContactUs from "./contactUs/contactUs";
import Navbar from "./navbarNews/navbarNews";
import Footer from "./footer/footer";
import { scroller, animateScroll } from "react-scroll";
import burger from "../assets/burgermenu.svg";
import mobilex from "../assets/mobile-x.svg";
import Sidebar from "../components/sidebar/Sidebar";
import { useState, useEffect } from "react";
import Map from "../components/map/Map";
import MobileApp from "../components/cardContent/MobileApp";
import WebPlatform from "../components/cardContent/WebPlatform";
import { cardsArray, frameArray, frameArrayEn } from "./rawData";
import ProductIntro from "../pages/product-intro/productIntro";
import Module from "./oneriModule";
import webappinfo from "../assets/webappinfo.svg";
import webappinfoEn from "../assets/webappinfoEn.svg";
import Popup from "./popup";
import { DatePicker } from "antd";
import VideoHeader from "../components/videoHeader/videoHeader";

const LandingPage = ({ isEnglish, setIsEnglish }) => {
  const [active, setActive] = useState("Back Office");
  const [show, setShow] = useState(false);
  const scrollToLogo = () => {
    scroller.scrollTo("root", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  function scrollToTop() {
    animateScroll.scrollToTop({
      duration: 500,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }

  const render = {
    "Front Office": <MobileApp isEnglish={isEnglish} />,
    "Back Office": <WebPlatform isEnglish={isEnglish} />,
  };

  return (
    <>
      <div className={`mobile__menu ${show ? "mobile__show-nav" : ""}`}>
        {/* <Popup /> */}
        <img
          style={{
            cursor: "pointer",
            marginTop: "30px",
            marginLeft: "40px",
            color: "black",
          }}
          src={mobilex}
          alt=""
          onClick={() => setShow(!show)}
        ></img>
        <Sidebar
          show={show}
          setShow={setShow}
          setIsEnglish={setIsEnglish}
          isEnglish={isEnglish}
        />
      </div>
      <div className="body">
        <div className="stickyheader">
          <header className="header">
            <div className="header__logo">
              <img onClick={scrollToLogo} src={oneriLogo} alt="" />
            </div>
            <Navbar
              setIsEnglish={setIsEnglish}
              isEnglish={isEnglish}
              direction={true}
            />
          </header>
          <header className="header__mobile">
            <img
              onClick={() => {
                setShow(!show);
                // navigate("/");
              }}
              src={burger}
              alt=""
            />
            <div className="header__logo">
              <img onClick={scrollToLogo} src={oneriLogo} alt="" />
            </div>
          </header>
        </div>
        <div className="first__container">
          <div className="section__one-laptop">
            <img src={landingPageDashboard} alt="" />
          </div>
          <div className="section__one_home">
          <div className="section__one_video">
          <VideoHeader />
          </div>
            <div className="section__one--left">
              <div className="section__one--text">
                {isEnglish ? (
                  <span>
                    The ONERI software solution was designed as a complete suite
                    for digitizing and optimizing sales processes.
                  </span>
                ) : (
                  <span>
                    Die Softwarelösung ONERI wurde als komplette Suite zur
                    Digitalisierung und Otimierung von Vertriebsprozessen
                    designt.
                  </span>
                )}
              </div>
              <div className="body__btn">
                <a
                  href={isEnglish ? "Oneri-en.pdf" : "/Oneri.pdf"}
                  target="__blank"
                >
                  <Button
                    title={isEnglish ? "Product Catalog" : "Produktkatalog"}
                    value="download"
                    classes="btn__view"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="product__intro">
            <ProductIntro isEnglish={isEnglish} />
          </div>
          <div className="product__info">
            {isEnglish ? (
              <img src={webappinfoEn} alt="" />
            ) : (
              <img src={webappinfo} alt="" />
            )}
          </div>
          <div className="section__two">
            <div className="header__section__two">
              <h1 className="hader__section__text">
                {isEnglish ? "Application" : "Anwendung"}
                <span className="dot">.</span>
              </h1>
            </div>
            <div className="section__two--cards">
              {cardsArray.map((element) => (
                <Cards
                  title={element.title}
                  subtitle={element.subtitle}
                  icon={element.icon}
                  classes={element.title === active ? "active" : ""}
                  onClick={() => setActive(element.title)}
                />
              ))}
            </div>
            {render[active]}
          </div>
        </div>
        <Module isEnglish={isEnglish} />
        <div className="section__three">
          {isEnglish
            ? frameArrayEn.map((item) => (
                <Frame
                  image={item.image}
                  header={item.header}
                  title={item.title}
                  paragraph={item.paragraph}
                />
              ))
            : frameArray.map((item) => (
                <Frame
                  image={item.image}
                  header={item.header}
                  title={item.title}
                  paragraph={item.paragraph}
                />
              ))}
        </div>
        <Partners isEnglish={isEnglish} />
        <Testimonials isEnglish={isEnglish} />
        <ContactUs isEnglish={isEnglish} />
        <Map userQuantity={5} ordersQuantity={100} isEnglish={isEnglish} />
        <Footer isEnglish={isEnglish} />
        
      </div>
    </>
  );
};

export default LandingPage;

import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import SingleBreadCrumb from "./SingleBreadCrumb";

const BreadCrumb = ({ paths, isEnglish }) => {
  return (
    <div className="BreadCrumb">
      {paths?.map((item, index) => {
        return (
          <Link className="BreadCrumb__link" to={item.path}>
            <SingleBreadCrumb
              showSlash={index < paths.length - 1}
              active={item?.active}
              name={isEnglish ? item.titleEn : item.title}
            />
          </Link>
        );
      })}
    </div>
  );
};

export default BreadCrumb;

import React, { useEffect, useState } from "react";
import NavBarDesktop from "../navbarNews/navbarNewsDesktop";
import "./campaign.css";
import Footer from "../footer/footer";
import { contentData } from "./helper";
import Section from "../../components/section";

const Campaign = ({ isEnglish, setIsEnglish }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight;
    const containers = document.querySelectorAll(".scroll-line-container");
    containers.forEach((container, index) => {
      const containerTop =
        container.getBoundingClientRect().top + window.scrollY;
      const percentage = Math.min(
        1,
        (scrollPosition - containerTop) / container.offsetHeight
      );
      const filledLine = container.querySelector(".scroll-line-filled");
      filledLine.style.height = percentage * 100 + "%";
      filledLine.style.backgroundColor = `rgba(108, 30, 176, ${percentage})`;
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollZoom = () => {
    const sections = document.querySelectorAll(".zoom-effect");
    const isMobile = window.innerWidth <= 920;
    const scrollPosition = window.scrollY;

    sections.forEach((section) => {
      const imgs = section.querySelectorAll("img");
      const offset = isMobile ? 200 : 400;
      const sectionPosition =
        section.getBoundingClientRect().top + window.scrollY - offset;
      const distanceFromTop = scrollPosition - sectionPosition;

      if (distanceFromTop > -200 && distanceFromTop < 100) {
        imgs.forEach((img) => {
          if (isMobile) {
            img.style.transform = "scale(1.15)";
          } else {
            section.classList.add("zoomed-in");
          }
        });
      } else {
        imgs.forEach((img) => {
          if (isMobile) {
            img.style.transform = "scale(1)";
          } else {
            section.classList.remove("zoomed-in");
          }
        });
      }
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScrollZoom);
    return () => {
      window.removeEventListener("scroll", handleScrollZoom);
    };
  }, []);

  return (
    <div className="container">
      <NavBarDesktop
        direction={true}
        isEnglish={isEnglish}
        setIsEnglish={setIsEnglish}
      />
      <div className="header-text-placement">
        <div>
          <h1>
            {isEnglish
              ? "Contract Management and Sales Processes"
              : "Vertragsmanagement und Vertriebsprozesse "}
          </h1>
        </div>
        <p className="header-text">
          {isEnglish
            ? "✨ Curious how ONERI manages to optimize contract management and make the entire workflow uninterrupted from start to finish? Here's a look behind the scenes! 🔍👇"
            : "✨ Neugierig, wie ONERI es schafft, das Vertragsmanagement zu optimieren und den gesamten Workflow von Anfang bis Ende ununterbrochen zu gestalten? Hier ist ein Blick hinter den Kulissen! 🔍👇"}
        </p>
      </div>
      {contentData.map((section, index) => (
        <Section
          section={section}
          key={index}
          handleImageClick={handleImageClick}
          isEnglish={isEnglish}
        />
      ))}
      <Footer isEnglish={isEnglish} />
      {selectedImage && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeModal}>
              &times;
            </button>
            <img src={selectedImage} alt="Full size" className="modal-image" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Campaign;

import React, { useState } from "react";
import burger from "../../assets/burgermenu.svg";
import mobilex from "../../assets/mobile-x.svg";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../navbarNews/navbarNews";
import oneriLogo from "../../assets/oneri_logo.png";
import { useNavigate } from "react-router";

const NavBarDesktop = ({ isEnglish, setIsEnglish }) => {
  const [showMenu, setShowMenu] = useState(false);

  const navigate = useNavigate();
  return (
    <>
      <div className={`mobile__menu ${showMenu ? "mobile__show-nav" : ""}`}>
        <img
          style={{ cursor: "pointer", marginTop: "30px", marginLeft: "40px" }}
          src={mobilex}
          alt=""
          onClick={() => setShowMenu(!showMenu)}
        ></img>
        <Sidebar
          show={showMenu}
          setShow={setShowMenu}
          setIsEnglish={setIsEnglish}
          isEnglish={isEnglish}
        />
      </div>
      <div className="stickyheader">
        <header className="header">
          <div className="header__logo" onClick={() => navigate("/")}>
            <img src={oneriLogo} alt="" />
          </div>
          <Navbar
            direction={true}
            isEnglish={isEnglish}
            setIsEnglish={setIsEnglish}
          />
        </header>
        <header className="header__mobile">
          <img onClick={() => setShowMenu(!showMenu)} src={burger} alt=""></img>
          <div className="header__logo" onClick={() => navigate("/")}>
            <img src={oneriLogo} alt="" />
          </div>
        </header>
      </div>
    </>
  );
};

export default NavBarDesktop;
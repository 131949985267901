import React, { useState } from "react";
import Title from "../../components/Title";
import "./testimonials.css";
import SimpleSlider from "../../components/slider/SimpleSlider";

const Testimonials = ({ isEnglish }) => {
  return (
    <div className="testimonial__container">
      <div>
        <Title header={isEnglish ? "Testimonials" : "Referenzen"} />
      </div>
      <div className="testimonials__section1">
        <SimpleSlider isEnglish={isEnglish} />
      </div>
    </div>
  );
};

export default Testimonials;

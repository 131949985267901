import React from "react";
import "./index.css";

const Frame = ({ header, title, paragraph, image }) => {
  return (
    <div className="section__three__child" >
      <div className="frame__card">
        <img src={image} alt="" />
        <p className="frame__header">{header}</p>
        <p className="frame__title">{title}</p>
        <div className="frame__paragraph">{/* <p>{paragraph}</p> */}</div>
      </div>
    </div>
  );
};

export default Frame;

import React from "react";
import Navbar from "../navbarNews/navbarNews";
import "./FeaturePage.css";
import oneriLogo from "../../assets/oneri_logo.png";
import { useParams, useNavigate } from "react-router-dom";
import Dropdown from "./Dropdown";
import { useEffect } from "react";
import { featuresJson, featuresJsonEn } from "./features";
import { useState } from "react";
import tick from "../../assets/tick.svg";
import mobilex from "../../assets/mobile-x.svg";
import Sidebar from "../../components/sidebar/Sidebar";
import burger from "../../assets/burgermenu.svg";
import BreadCrumb from "../../components/breadcrumb";

const FeaturePage = ({ isEnglish, setIsEnglish }) => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const { id: paramId } = useParams();
  const [data, setData] = useState({
    title: "",
    subtitle: "",
    content: "",
    image: null,
    content2: "",
    content3: "",
    content4: "",
    content5: "",
    content6: "",
    content7: "",
    content8: "",
    content9: "",
    content10: "",
    options: [],
    options2: [],
    options3: [],
    options4: [],
  });

  useEffect(() => {
    setData(featuresJson.find((item) => item.id === paramId));
  }, [paramId]);

  return (
    <div className="feature-navbar_header">
      <div className={`mobile__menu ${showMenu ? "mobile__show-nav" : ""}`}>
        <img
          style={{ cursor: "pointer", marginTop: "30px", marginLeft: "40px" }}
          src={mobilex}
          alt=""
          onClick={() => setShowMenu(!showMenu)}
        ></img>
        <Sidebar
          show={showMenu}
          setShow={setShowMenu}
          setIsEnglish={setIsEnglish}
          isEnglish={isEnglish}
        />
      </div>
      <div className="stickyheader">
        <header className="header">
          <div className="header__logo" onClick={() => navigate("/")}>
            <img src={oneriLogo} alt="" />
          </div>
          <Navbar
            direction={true}
            isEnglish={isEnglish}
            setIsEnglish={setIsEnglish}
          />
        </header>
        <header className="header__mobile">
          <img onClick={() => setShowMenu(!showMenu)} src={burger} alt=""></img>
          <div className="header__logo" onClick={() => navigate("/")}>
            <img src={oneriLogo} alt="" />
          </div>
        </header>
      </div>
      <div className="feature-container">
        <div className="feature-sidebar">
          <Dropdown paramId={paramId} isEnglish={isEnglish} />
        </div>
        <div className="feature-content-user">
          <div className="feature-content-user__">
            <BreadCrumb paths={data?.paths} isEnglish={isEnglish} />
            <div className="feature-content-sales">
              <div className="feature-content_item"></div>
              <h1>{isEnglish ? data?.titleEn : data?.titleDe}</h1>
            </div>
            <div className="feature-subtitle-content">
              <span>{isEnglish ? data?.subtitleEn : data?.subtitle}</span>
              <br />
              <span className="feature-content_span">
                {isEnglish ? data?.contentEn : data?.content}
              </span>
              <div className="feature-bottom_section">
                <div className="feature-bottom-border"></div>
                {isEnglish
                  ? data?.optionsEn?.map((item) => {
                      return (
                        <div className="feature-bottom-section-text">
                          <img src={tick} alt="" />
                          <span>{item}</span>
                        </div>
                      );
                    })
                  : data?.options?.map((item) => {
                      return (
                        <div className="feature-bottom-section-text">
                          <img src={tick} alt="" />
                          <span>{item}</span>
                        </div>
                      );
                    })}
              </div>
            </div>
            {data?.image && (
              <div className="feature__images">
                <img className="imazhe" src={data?.image} alt="" />
              </div>
            )}
            {data?.content2 && (
              <div className="feature-subtitle-content2">
                <span className="feature-content2">
                  {isEnglish ? data?.content2En : data?.content2}
                </span>
              </div>
            )}
            {data?.footer && (
              <div className="feature-footer">
                {isEnglish ? data?.footerEns : data?.footer}
              </div>
            )}
            {data?.options2?.[0] && (
              <div className="feature-bottom_section2">
                {isEnglish
                  ? data?.options2En?.map((item) => {
                      return (
                        <div className="feature-bottom-section-text">
                          <img src={tick} alt="" />
                          <span>{item}</span>
                        </div>
                      );
                    })
                  : data?.options2?.map((item) => {
                      return (
                        <div className="feature-bottom-section-text">
                          <img src={tick} alt="" />
                          <span>{item}</span>
                        </div>
                      );
                    })}
              </div>
            )}
            {data?.content3 && (
              <div className="feature-subtitle-content2">
                <span className="feature-content2">
                  {isEnglish ? data?.content3En : data?.content3}
                </span>
              </div>
            )}
            {data?.content4 && (
              <div className="feature-subtitle-content2">
                <span className="feature-content2">
                  {isEnglish ? data?.content4En : data?.content4}
                </span>
              </div>
            )}
            {data?.options3?.[0] && (
              <div className="feature-bottom_section2">
                {isEnglish
                  ? data?.options3En?.map((item) => {
                      return (
                        <div className="feature-bottom-section-text">
                          <img src={tick} alt="" />
                          <span>{item}</span>
                        </div>
                      );
                    })
                  : data?.options3?.map((item) => {
                      return (
                        <div className="feature-bottom-section-text">
                          <img src={tick} alt="" />
                          <span>{item}</span>
                        </div>
                      );
                    })}
              </div>
            )}
            {data?.content5 && (
              <div className="feature-subtitle-content5">
                <span className="feature-content2">
                  {isEnglish ? data?.content5En : data?.content5}
                </span>
              </div>
            )}
            {data?.options4?.[0] && (
              <div className="feature-bottom_section3">
                {isEnglish
                  ? data?.options4En?.map((item) => {
                      return (
                        <div className="feature-bottom-section-text">
                          <img src={tick} alt="" />
                          <span>{item}</span>
                        </div>
                      );
                    })
                  : data?.options4?.map((item) => {
                      return (
                        <div className="feature-bottom-section-text">
                          <img src={tick} alt="" />
                          <span>{item}</span>
                        </div>
                      );
                    })}
              </div>
            )}
            {data?.content6 && (
              <div className="feature-subtitle-content6">
                <span className="feature-content2">
                  {isEnglish ? data?.content6En : data?.content6}
                </span>
              </div>
            )}
            {data?.options5?.[0] && (
              <div className="feature-bottom_section4">
                {isEnglish
                  ? data?.options5eN?.map((item) => {
                      return (
                        <div className="feature-bottom-section-text">
                          <img src={tick} alt="" />
                          <span>{item}</span>
                        </div>
                      );
                    })
                  : data?.options5?.map((item) => {
                      return (
                        <div className="feature-bottom-section-text">
                          <img src={tick} alt="" />
                          <span>{item}</span>
                        </div>
                      );
                    })}
              </div>
            )}
            {data?.content7 && (
              <div className="feature-subtitle-content7">
                <span className="feature-content2">
                  {isEnglish ? data?.content7En : data?.content7}
                </span>
              </div>
            )}
            {data?.content8 && (
              <div className="feature-subtitle-content8">
                <span className="feature-content2">
                  {isEnglish ? data?.content8En : data?.content8}
                </span>
              </div>
            )}
            {data?.options6?.[0] && (
              <div className="feature-bottom_section6">
                {isEnglish
                  ? data?.options6En?.map((item) => {
                      return (
                        <div className="feature-bottom-section-text">
                          <img src={tick} alt="" />
                          <span>{item}</span>
                        </div>
                      );
                    })
                  : data?.options6?.map((item) => {
                      return (
                        <div className="feature-bottom-section-text">
                          <img src={tick} alt="" />
                          <span>{item}</span>
                        </div>
                      );
                    })}
              </div>
            )}
            {data?.content9 && (
              <div className="feature-subtitle-content9">
                <span className="feature-content2">
                  {isEnglish ? data?.content9En : data?.content9}
                </span>
              </div>
            )}
            {data?.content10 && (
              <div className="feature-subtitle-content10">
                <span className="feature-content2">
                  {isEnglish ? data?.content10En : data?.content10}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturePage;

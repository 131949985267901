import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LandingPage from "./pages";
import FeaturePage from "./pages/features/FeaturePage";
import Partners from "./pages/our partners/ourPartners";
import { useEffect, useState } from "react";
import PdfComponent from "./pages/pdfComponent";
import Calculator from "./pages/calculator/calculator";
import PrivacyPolicy from "./pages/privacyPolicy";
import PdfComponentEn from "./pages/pdfComponent/pdfEn";
import QrVideoComponent from "./pages/qrVideoPage";
import Termin from "./pages/termin";
import SuccessPage from "./pages/termin/successPage";
import Impressum from "./pages/impressum/Impressum";
import Contact from "./pages/contact/Contact";
import Price from "./pages/price/Price";
import News from "./pages/news/news";
import InsideNews from "./pages/insideNews/insideNews";
import Campaign from "./pages/campaign/Campaign";
import SalesTracker from "./pages/salesTracker";

function App() {
  const [isEnglish, setIsEnglish] = useState(false);

  useEffect(() => {
    const lang = localStorage.getItem("languageVal");
    setIsEnglish(lang === "en" ? true : false);
  }, [isEnglish]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <LandingPage setIsEnglish={setIsEnglish} isEnglish={isEnglish} />
          }
        />
        <Route
          path="/partners"
          element={
            <Partners setIsEnglish={setIsEnglish} isEnglish={isEnglish} />
          }
        />
        <Route
          path="/modules/:id"
          element={
            <FeaturePage setIsEnglish={setIsEnglish} isEnglish={isEnglish} />
          }
        />
        <Route
          path="/Oneri.pdf"
          element={
            <PdfComponent setIsEnglish={setIsEnglish} isEnglish={isEnglish} />
          }
        />
        <Route
          path="/Oneri-en.pdf"
          element={
            <PdfComponentEn setIsEnglish={setIsEnglish} isEnglish={isEnglish} />
          }
        />
        {/*  <Route
          path="/calculator"
          element={
            <Calculator setIsEnglish={setIsEnglish} isEnglish={isEnglish} />
          }
        />  */}
        <Route
          path="/privacyPolicy"
          element={
            <PrivacyPolicy setIsEnglish={setIsEnglish} isEnglish={isEnglish} />
          }
        />
        <Route
          path="/termin"
          element={<Termin setIsEnglish={setIsEnglish} isEnglish={isEnglish} />}
        />
        {/*   <Route
          path="/termin/success"
          element={
            <SuccessPage setIsEnglish={setIsEnglish} isEnglish={isEnglish} />
          }
        />   */}
    <Route
          path="/impressum"
          element={
            <Impressum setIsEnglish={setIsEnglish} isEnglish={isEnglish} />
          }
        />  
        <Route
          path="/contact"
          element={
            <Contact setIsEnglish={setIsEnglish} isEnglish={isEnglish} />
          }
        />
        {/*   <Route
          path="/presentation"
          element={
            <QrVideoComponent
              setIsEnglish={setIsEnglish}
              isEnglish={isEnglish}
            />
        />
           */}
        <Route
          path="/news"
          element={<News setIsEnglish={setIsEnglish} isEnglish={isEnglish} />}
        />
        <Route
          path="/news/:slug"
          element={
            <InsideNews setIsEnglish={setIsEnglish} isEnglish={isEnglish} />
          }
        />
        <Route
          path="/Vertragsmanagement-und-Vertriebsprozesse"
          element={<Campaign setIsEnglish={setIsEnglish} isEnglish={isEnglish} />}
          />
        <Route
          path="/sales-tracker"
          element={<SalesTracker setIsEnglish={setIsEnglish} isEnglish={isEnglish} />}
        />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import "./index.css";
import Footer from "../footer/footer";
import logo from "../../assets/oneri_logo.png";
import { privacyData } from "./privacyPolicyDatas";
import { useNavigate } from "react-router";
import { scroller, animateScroll } from "react-scroll";
import burger from "../../assets/burgermenu.svg";
import oneriLogo from "../../assets/oneri_logo.png";
import mobilex from "../../assets/mobile-x.svg";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../navBar/navBar";

function PrivacyPolicy({ isEnglish, setIsEnglish }) {
  const [data, setData] = useState(privacyData);
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const currentDate = new Date().getFullYear();

  useEffect(() => {
    scrollToTop();
  }, []);
  function scrollToTop() {
    animateScroll.scrollToTop({
      duration: 500,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  }

  return (
    <>
      <div className={`mobile__menu ${showMenu ? "mobile__show-nav" : ""}`}>
        <img
          style={{ cursor: "pointer", marginTop: "30px", marginLeft: "40px" }}
          src={mobilex}
          alt=""
          onClick={() => setShowMenu(!showMenu)}
        ></img>
        <Sidebar
          show={showMenu}
          setShow={setShowMenu}
          setIsEnglish={setIsEnglish}
          isEnglish={isEnglish}
        />
      </div>
      <div className="stickyheader">
        <header className="header">
          <div className="header__logo" onClick={() => navigate("/")}>
            <img src={oneriLogo} alt="" />
          </div>
          <Navbar
            direction={true}
            isEnglish={isEnglish}
            setIsEnglish={setIsEnglish}
          />
        </header>
        <header className="header__mobile">
          <img onClick={() => setShowMenu(!showMenu)} src={burger} alt=""></img>
          <div className="header__logo" onClick={() => navigate("/")}>
            <img src={oneriLogo} alt="" />
          </div>
        </header>
      </div>

      <div className="privacyPolicy__text__container">
        <div className="privacyPolicy__text__section1">
          <span className="privacyPolicy__text__header">
            {isEnglish
              ? "Privacy Policy for ONERI"
              : "Datenschutzrichtlinie für ONERI"}
          </span>
          <span className="privacyPolicy__text__text">
            {isEnglish
              ? "Your privacy is very important to us, and we are committed to protecting your personal data in compliance with the applicable data protection regulations. This privacy policy is intended to inform you about the personal data we collect, how we use it, and your rights regarding your data."
              : "Daten sehr ernst und verpflichten uns, Ihre Privatsphäre zu schützen und Ihre personenbezogenen Daten in Übereinstimmung mit den geltenden Datenschutzgesetzen zu behandeln. Diese Datenschutzerklärung soll Sie darüber informieren, welche personenbezogenen Daten wir erfassen, wie wir diese verwenden und welche Rechte Sie in Bezug auf Ihre Daten haben."}
          </span>
          <span className="privacyPolicy__text__header">
            1. {isEnglish ? "Controller" : " Verantwortlicher"}
          </span>
          <span className="privacyPolicy__text__title">
            {isEnglish
              ? "The controller responsible for the processing of your personal data according to the General Data Protection Regulation (GDPR) is: "
              : "Verantwortlicher für die Verarbeitung Ihrer personenbezogenen Daten im Sinne der Datenschutz-Grundverordnung (DSGVO) ist:"}
          </span>
          <span className="privacyPolicy__text__text">
            TECH FRAME DEUTSCHLAND
            <br /> Adresse: Höhenrainer Str. 5, 81477 München, Germany
            <br />
            E-Mail: info@oneri.de / info@tframe.de
          </span>
          <span className="privacyPolicy__text__header">
            2. {isEnglish ? "Data We Collect" : "Daten, die wir erheben"}
          </span>
          <span className="privacyPolicy__text__title">
            {isEnglish
              ? "We process personal data only to the extent necessary for the provision of a functional website and our services. The types of data we collect include:"
              : "Wir verarbeiten personenbezogene Daten nur, soweit dies zur Bereitstellung einer funktionsfähigen Website sowie unserer Inhalte und Dienstleistungen erforderlich ist. Zu den Arten von Daten, die wir erheben, gehören:"}
          </span>
          {isEnglish ? (
            <span className="privacyPolicy__text__text">
              Contact Information: Name, email address, phone number, postal
              address. <br />
              Usage Data: IP address, browser type, access times, pages visited.
              <br />
              Contract Data: Information you provide to use our services,
              including company details, contract information, and payment data.
              <br />
              Technical Data: Log files and information about the device used.
            </span>
          ) : (
            <span className="privacyPolicy__text__text">
              Kontaktdaten: Name, E-Mail-Adresse, Telefonnummer, Postanschrift.
              <br />
              Nutzungsdaten: IP-Adresse, Browser-Typ, Zugriffszeiten, besuchte
              Seiten.
              <br />
              Vertragsdaten: Informationen, die Sie uns zur Verfügung stellen,
              um unsere Dienstleistungen zu nutzen, einschließlich Firmendaten,
              Vertragsinformationen und Zahlungsdaten.
              <br />
              Technische Daten: Log-Dateien und Informationen über das
              verwendete Gerät.
            </span>
          )}
          <span className="privacyPolicy__text__header">
            3.{" "}
            {isEnglish ? "Purposes of Data Processing" : " Verarbeitungszwecke"}
          </span>
          <span className="privacyPolicy__text__title">
            {isEnglish
              ? "We use the collected data for the following purposes:"
              : "Wir verwenden die erhobenen Daten zu folgenden Zwecken:"}
          </span>
          {isEnglish ? (
            <span className="privacyPolicy__text__text">
              To provide and improve our services.
              <br />
              To manage contractual and business relationships.
              <br />
              To analyze and optimize our website and marketing efforts.
              <br />
              To comply with legal obligations.
            </span>
          ) : (
            <span className="privacyPolicy__text__text">
              Zur Bereitstellung und Verbesserung unserer Dienste. <br /> Zur
              Durchführung von Vertrags- und Geschäftsbeziehungen.
              <br /> Zur Analyse und Optimierung unserer Website und
              Marketingmaßnahmen.
              <br /> Zur Einhaltung gesetzlicher Anforderungen.
            </span>
          )}
          <span className="privacyPolicy__text__header">
            4.
            {isEnglish
              ? "Legal Basis for Data Processing"
              : "Rechtsgrundlagen für die Datenverarbeitung"}
          </span>
          <span className="privacyPolicy__text__title">
            {isEnglish
              ? "The processing of your personal data is carried out exclusively on the basis of the following legal grounds:"
              : "Die Verarbeitung Ihrer personenbezogenen Daten erfolgt ausschließlich auf der Grundlage der folgenden Rechtsgrundlagen:"}
          </span>
          {isEnglish ? (
            <span className="privacyPolicy__text__text">
              Consent (Art. 6(1)(a) GDPR): When you have given us your consent
              for certain processing activities, such as subscribing to
              newsletters. <br />
              Contractual Fulfillment (Art. 6(1)(b) GDPR): To fulfill a contract
              to which you are a party. <br />
              Legitimate Interests (Art. 6(1)(f) GDPR): To improve our services,
              secure our IT infrastructure, and for marketing purposes. <br />
            </span>
          ) : (
            <span className="privacyPolicy__text__text">
              Einwilligung (Art. 6 Abs. 1 lit. a DSGVO): Wenn Sie uns Ihre
              Einwilligung für bestimmte Verarbeitungen erteilt haben, z.B. für
              den Versand von Newslettern. <br />
              Vertragserfüllung (Art. 6 Abs. 1 lit. b DSGVO): Zur Erfüllung
              eines Vertrages, an dem Sie beteiligt sind. <br />
              Berechtigtes Interesse (Art. 6 Abs. 1 lit. f DSGVO): Zur
              Verbesserung unserer Dienstleistungen, zur Absicherung unserer
              IT-Sicherheit und für Marketingzwecke.
            </span>
          )}

          <span className="privacyPolicy__text__header">
            5.{" "}
            {isEnglish
              ? "Data Retention and Deletion"
              : "Speicherung und Löschung Ihrer Daten"}
          </span>
          <span className="privacyPolicy__text__text">
            {isEnglish
              ? "We retain your personal data only for as long as it is necessary to fulfill the purposes for which it was collected or to comply with legal obligations. After the retention period expires, your data will be securely deleted or anonymized."
              : "Wir speichern Ihre personenbezogenen Daten nur so lange, wie es für die Zwecke, für die sie erhoben wurden, notwendig ist oder um gesetzliche Anforderungen zu erfüllen. Nach Ablauf dieser Fristen werden Ihre Daten sicher gelöscht oder anonymisiert."}
          </span>
          <span className="privacyPolicy__text__header">
            6.
            {isEnglish
              ? "Sharing Data with Third Parties"
              : "Weitergabe von Daten an Dritte"}
          </span>
          <span className="privacyPolicy__text__title">
            {isEnglish
              ? "Your personal data will not be shared with third parties without your express consent, except:"
              : "Ihre personenbezogenen Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben, außer:"}
          </span>
          {isEnglish ? (
            <span className="privacyPolicy__text__text">
              Service Providers: We work with external service providers who
              assist us in providing our services. This includes our hosting
              provider Amazon Web Services (AWS), which stores our data on
              servers located in EU-Central-1 (Frankfurt). <br />
              Legal Obligations: If we are legally required to disclose your
              data, for example, to regulatory authorities or courts.
            </span>
          ) : (
            <span className="privacyPolicy__text__text">
              Dienstleister: Wir arbeiten mit externen Dienstleistern zusammen,
              die uns bei der Erbringung unserer Dienstleistungen unterstützen.
              Dies umfasst insbesondere den Hosting-Anbieter Amazon Web Services
              (AWS), der unsere Daten auf Servern in der Region EU-Central-1
              (Frankfurt) speichert. Gesetzliche Verpflichtungen: Wenn wir
              gesetzlich dazu verpflichtet sind, Ihre Daten weiterzugeben,
              beispielsweise an Aufsichtsbehörden oder Gerichte.
            </span>
          )}
          <span className="privacyPolicy__text__header">
            7. {isEnglish ? "Data Security" : "Datensicherheit"}
          </span>
          <span className="privacyPolicy__text__text">
            {isEnglish
              ? "We use technical and organizational security measures to protect your personal data from unauthorized access, loss, or manipulation. Our servers are hosted by Amazon Web Services (AWS) in Frankfurt, Germany, which complies with the highest security standards and GDPR requirements."
              : "Wir setzen technische und organisatorische Sicherheitsmaßnahmen ein, um Ihre personenbezogenen Daten vor unbefugtem Zugriff, Verlust oder Manipulation zu schützen. Unsere Server werden von Amazon Web Services (AWS) in Frankfurt, Deutschland gehostet, was den höchsten Sicherheitsstandards entspricht und die Einhaltung der DSGVO gewährleistet."}
          </span>
          <span className="privacyPolicy__text__header">
            8. {isEnglish ? "Your Rights" : "Ihre Rechte"}
          </span>
          <span className="privacyPolicy__text__title">
            {isEnglish
              ? "You have the following rights in relation to the processing of your personal data:"
              : "Sie haben folgende Rechte in Bezug auf die Verarbeitung Ihrer personenbezogenen Daten:"}
          </span>
          {isEnglish ? (
            <span className="privacyPolicy__text__text">
              Right to Access: You have the right to receive confirmation as to
              whether your personal data is being processed and to access your
              data. <br />
              Right to Rectification: You have the right to correct inaccurate
              or incomplete data.
              <br />
              Right to Erasure: You can request the deletion of your data if it
              is no longer needed or if you withdraw your consent.
              <br />
              Right to Restrict Processing: You have the right to restrict the
              processing of your data, for example, if the data is processed
              unlawfully.
              <br />
              Right to Data Portability: You have the right to receive your
              personal data in a structured, commonly used, and machine-readable
              format.
              <br />
              Right to Object: You can object to the processing of your personal
              data if it is based on legitimate interests.
            </span>
          ) : (
            <span className="privacyPolicy__text__text">
              Auskunft: Sie haben das Recht, eine Bestätigung darüber zu
              erhalten, ob Ihre personenbezogenen Daten verarbeitet werden, und
              Zugriff auf diese Daten zu erhalten.
              <br />
              Berichtigung: Sie haben das Recht, unrichtige oder unvollständige
              Daten korrigieren zu lassen.
              <br />
              Löschung: Sie können die Löschung Ihrer Daten verlangen, wenn
              diese nicht mehr benötigt werden oder wenn Sie Ihre Einwilligung
              widerrufen.
              <br />
              Einschränkung der Verarbeitung: Sie haben das Recht, die
              Verarbeitung Ihrer Daten einzuschränken, z.B. wenn die Daten
              unrechtmäßig verarbeitet wurden.
              <br />
              Datenübertragbarkeit: Sie haben das Recht, Ihre personenbezogenen
              Daten in einem strukturierten, gängigen und maschinenlesbaren
              Format zu erhalten.
              <br />
              Widerspruch: Sie können der Verarbeitung Ihrer personenbezogenen
              Daten widersprechen, wenn diese auf einem berechtigten Interesse
              beruht.
            </span>
          )}
          <span className="privacyPolicy__text__header">
            9.{" "}
            {isEnglish
              ? "Changes to This Privacy Policy"
              : "Änderungen an dieser Datenschutzerklärung"}
          </span>
          <span className="privacyPolicy__text__text">
            {isEnglish
              ? "We reserve the right to amend this privacy policy at any time to reflect legal or technical developments. The most current version can always be found on our website"
              : "Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit zu ändern, um sie an rechtliche oder technische Entwicklungen anzupassen. Die aktuellste Version finden Sie immer auf unserer Website."}
          </span>
          <span className="privacyPolicy__text__header">
            10. {isEnglish ? "Contact" : "Kontakt"}
          </span>
          <span className="privacyPolicy__text__text">
            {isEnglish
              ? "If you have any questions about the processing of your data or wish to exercise your rights, you can contact us using the details provided above."
              : "Wenn Sie Fragen zur Verarbeitung Ihrer Daten haben oder Ihre Rechte ausüben möchten, können Sie uns jederzeit unter den oben genannten Kontaktdaten erreichen."}
          </span>
        </div>

        <div className="privacyPolicy__header">
          <img
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
            src={logo}
            alt=""
          />
          <span>© Copyright {currentDate} ONERI</span>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;

{
  /* <span className="privacyPolicy__text__header">
        1. Datenschutz auf einen Blick
        </span>
        <span className="privacyPolicy__text__title">
        Allgemeine Hinweise
        </span>
        <span className="privacyPolicy__text__text">
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was
        mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
        besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
        persönlich identifiziert werden können. Ausführliche Informationen
        zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
        aufgeführten Datenschutzerklärung.
</span> */
}

{
  /* <span className="privacyPolicy__text__subTitle">JETON</span> */
}

<ul className="privacyPolicy__text__list">
  <li className="privacyPolicy__text__list__li"></li>
  <li className="privacyPolicy__text__list__li"></li>
  <li className="privacyPolicy__text__list__li"></li>
  <li className="privacyPolicy__text__list__li"></li>
</ul>;

import React, { useState } from "react";
import "./productIntro.css";
import tablet from "../../assets/tablets.svg";
import reporting from "../../assets/modulesReporting.png";
const ProductIntro = ({ isEnglish }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const expandableTextClass = isExpanded
    ? "expandable-text show-more"
    : "expandable-text";
  return (
    <div className="productintro-container">
      <div className="productintro-title">
        {isEnglish ? (
          <span>
            The all-in-one sales optimization suite designed to help businesses
            digitalize and streamline their sales processes
            <span style={{ color: "#0E88F2" }}>.</span>
          </span>
        ) : (
          <span>
            Ihre Rundum-Komplettlösung für hoch effektiven Direktvertrieb
            <span style={{ color: "#0E88F2" }}>.</span>
          </span>
        )}
      </div>
      <div className="productintro-content">
        <div className="productintro-title_p">
          {isEnglish ? (
            <span className={expandableTextClass}>
              With a wide range of modules, ONERI makes it effortless to track
              and manage sales activities, establish multi-level bonuses for
              your sales team, and promptly calculate and settle provisions.
              ONERI also empowers sales teams to work smarter. Our Sales tracker
              tool allows sales reps to plan their day with ease, while the
              integrated communication app keeps everyone in the loop and
              facilitates collaboration. With ONERI, sales teams can also easily
              compose and digitally sign contracts on the spot, even in areas
              with limited internet connectivity.
              <br />
              <br />
              And with the academy feature, ONERI provides sales teams with the
              tools and resources they need to stay on top of their game. Our
              comprehensive training materials help teams enhance their skills,
              stay up-to-date on industry trends, and continually improve their
              performance. In short, ONERI is the ultimate tool for businesses
              looking to optimize their sales processes, enhance collaboration,
              and drive revenue growth.
            </span>
          ) : (
            <span className={expandableTextClass}>
              Die Softwarelösung ONERI wurde als komplette Suite zur
              Digitalisierung und Otimierung von Vertriebsprozessen designt. Mit
              einer großen Auswahl an Features und Funktionalitäten ermöglicht
              die ONERI-Suite, eine Verfolgung und Verwaltung aller Prozesse im
              Direktvertrieb in Echtzeit. Unter anderem ermöglicht ONERI eine
              mehrstufige Vergütungs- und Provisionsabrechnung auf Knopfdruck
              sowie eine sehr sehr effiziente und zeitnahe Vertriebssteuerung.
              <br />
              <br />
              ONERI ist die ultimative Software zur Optimierung und 100%igen
              Digitalisierung Ihrer Vertriebsprozesse. Sie ermöglicht eine
              Online-Zusammenarbeit über alle Vertriebsprozesse und führt bei
              konsequenter Einführung automatisch zu Transparenz und
              Umsatzwachstum im Unternehmen.
            </span>
          )}
          <div onClick={handleToggle} className="showMoreButton front">
            {isEnglish ? (
              <span>{isExpanded ? "Show less" : "Show more"}</span>
            ) : (
              <span>{isExpanded ? "Weniger anzeigen" : "Mehr anzeigen"}</span>
            )}
          </div>
        </div>
        <div className="productintro-img">
          <img src={reporting} alt="" className="product-img__image" />
        </div>
      </div>
    </div>
  );
};

export default ProductIntro;

import React, { useState } from "react";
import Title from "../../components/Title";
import "./contactUs.css";

const ContactUs = ({ isEnglish }) => {
  return (
    <div className="contactus__container">
      <Title header={isEnglish ? "Contact" : "Kontakt"} />
    </div>
  );
};

export default ContactUs;

import React from "react";
import "./index.css";

const SalesTrackerCard = ({ isEnglish, section }) => {
  return (
    <div className="sales-tracker-section-content-left-card">
      <div className="sales-tracker-section-content-left-card-box">
        <div className="sales-tracker-section-content-left-card-box-inside">
          <p className="sales-tracker-section-content-left-card-box-inside-title">
            {isEnglish ? section.cardTitle : section.cardTitleDe}
          </p>
          <p className="sales-tracker-section-content-left-card-box-inside-text">
            {isEnglish ? section.card : section.cardDe}
          </p>
        </div>
      </div>
    </div>
  );
};

export default SalesTrackerCard;

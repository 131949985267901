import React from "react";
import AboutUs from "../../pages/aboutUs/AboutUs";
import ContactUs from "../../pages/contactUs/contactUs";
import Partners from "../../pages/our partners/ourPartners";
import Testimonials from "../../pages/testimonials/testimonials";
import MobileApp from "../cardContent/MobileApp";
import Footer from "../../pages/footer/footer";
import Navbar from "../../pages/navbarNews/navbarNews";
import "./Sidebar.css";

function Sidebar({ show = false, setShow, setIsEnglish, isEnglish }) {
  return (
    <div className={` ${show ? "sidebar" : "dontShow"}`}>
      <ul>
        <Navbar
          mobile={true}
          setShow={setShow}
          show={show}
          setIsEnglish={setIsEnglish}
          isEnglish={isEnglish}
        />
      </ul>
    </div>
  );
}

export default Sidebar;

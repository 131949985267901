import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./navbarNews.css";
import { scroller } from "react-scroll";
import ToggleButton from "../../components/toggleButton/toggleButton";
import oneriLogo from "../../assets/mobile-oneri.png";

const Navbar = ({
  direction = false,
  mobile = false,
  show,
  setShow,
  setIsEnglish,
  isEnglish,
  showToggleButton = true,
  showImpressum = true,
}) => {
  const navigate = useNavigate();
  const checkFnct = (name, offset = 0) => {
    if (mobile === false) {
      scroller.scrollTo(name, {
        duration: 1000,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: offset,
      });
    } else {
      setShow(false);
      setTimeout(() => {
        scroller.scrollTo(name, {
          duration: 1000,
          delay: 0,
          smooth: "easeInOutQuart",
          offset: offset,
        });
      }, 300);
    }
  };

  useEffect(() => {
    const name = localStorage.getItem("scrollTo");
    if (name !== null) {
      setTimeout(() => {
        scroller.scrollTo(name, {
          duration: 1000,
          delay: 0,
          smooth: "easeInOutQuart",
          offset: 0,
        });
      }, 300);
    }
    localStorage.setItem("scrollTo", null);
  }, [localStorage.getItem("scrollTo")]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <div className={`${mobile ? "mobile" : "header__navbar"}`}>
      <ul
        className={`${direction ? "header__navbar-list" : "navbar__direction"}`}
      >
        <Link
          to="/"
          className="navbar-center-list"
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          <li
            onClick={() => {
              checkFnct("body");
              localStorage.setItem("scrollTo", "body");
            }}
          >
            {isEnglish ? "About us" : "ÜBER UNS"}
          </li>
        </Link>
        <Link
          to="/"
          className="navbar-center-list"
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          <li>
            {" "}
            <Link
              to="/modules/einleitung"
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              {isEnglish ? "Modules" : "Module"}
            </Link>
          </li>
        </Link>
        <Link
          to="/"
          className="navbar-center-list"
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          <li>
            {" "}
            <Link
              to="/news"
              onClick={scrollToTop}
              style={{ color: "inherit", textDecoration: "inherit" }}
            >
              {isEnglish ? "News" : "Nachrichten"}
            </Link>
          </li>
        </Link>
        <Link
          to="/"
          className="navbar-center-list"
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          <li
            onClick={() => {
              checkFnct("partners__container");
              localStorage.setItem("scrollTo", "partners__container");
            }}
          >
            {isEnglish ? "Our Costumers" : "Kunden"}
          </li>
        </Link>
        <Link
          to="/"
          className="navbar-center-list"
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          <li
            onClick={() => {
              checkFnct("testimonial__container");
              localStorage.setItem("scrollTo", "testimonial__container");
            }}
          >
            {isEnglish ? "Testimonials" : "Referenzen"}
          </li>
        </Link>
        {/* <Link to="/price" style={{ color: "inherit", textDecoration: "inherit" }}>
          <li>
            {isEnglish ? "Price" : "Preis"}
          </li>
        </Link> */}
        <Link
          to="/contact"
          className="navbar-center-list"
          style={{ color: "inherit", textDecoration: "inherit" }}
        >
          <li>{isEnglish ? "Contacts" : "Kontakt"}</li>
        </Link>

        {showToggleButton && <ToggleButton setIsEnglish={setIsEnglish} />}
      </ul>

      <img
        className={mobile ? "mobile-logo-oneri" : "loptop-logo-oneri"}
        src={oneriLogo}
      />
    </div>
  );
};

export default Navbar;

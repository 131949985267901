import React from "react";

const SliderBox = (props) => {
  return (
    <div className="testimonials__single-card">
      <div className="testimonials__profile">
        <img src={props.img} alt="" />
      </div>
      <div className="testimonials__card">
        <div className="testimonials__card--text">
          <p className="testimonials__card--text-in">{props.text}</p>
          <img src={props.icon} alt="" />
          <h3>{props.name}</h3>
        </div>
      </div>
    </div>
  );
};

export default SliderBox;

import React, { useState } from "react";
import "./ourPartners.css";
import deutscheglasfaser from "../../assets/deutscheglasfaser.svg";
import homenet from "../../assets/homenet.svg";
import locate from "../../assets/locate.svg";
import giganetz from "../../assets/giganetz.svg";
import tng from "../../assets/tng.svg";
import maxprom from "../../assets/maxprom.svg";
import Avc from "../../assets/Avc.svg";
import Title from "../../components/Title";
import VertriebsFluss from '../../assets/Vertriebsfluss.svg'
import Despos from '../../assets/despos.svg'
import Direktvertrieb from '../../assets/Direktvertrieb.svg'
import Maxprom from '../../assets/maxprom.svg'
import Maxprom2 from '../../assets/maxpromClient.svg'
const Partners = ({ isEnglish }) => {
  const partnersArray = [
    {
      name: deutscheglasfaser,
      extraClass: "partner-logo",
    },
    {
      name: homenet,
      extraClass: "partner-logo",
    },
    {
      name: locate,
      extraClass: "partner-logo",
    },
    {
      name: giganetz,
      extraClass: "partner-logo",
    },
    {
      name: tng,
      extraClass: "partner-logo",
    },
    // {
    //   name: Avc,
    //   extraClass: "partner-logo",
    // },
    {
      name: maxprom,
      extraClass: 'ibm',
    },
  ];

  return (
    <div className="partners__body">
      <div className="partners__container">
        <div className="partners__titleLogo">
          <Title header={isEnglish ? "Our Customers" : "Unsere Kunden"} />
          <div className="partners__logos">
            <img src={VertriebsFluss} alt="" className="partner__logos_class" />
            <img src={Despos} alt="" className="partner__logos_class" />
            <img src={Direktvertrieb} alt="" className="partner__logos_class" />
            <img src={Maxprom} alt="" className="partner__logos_class" />
            <img src={Maxprom2} alt="" className="partner__logos_class" />
          </div>
        </div>

        <div className="partners__container displayNone">
          <Title
            header={isEnglish ? "Supported Providers" : "Unterstützte Anbieter"}
          />
          <div className="partners__images">
            {partnersArray.map((partner, index) => {
              return (
                <img
                  src={partner.name}
                  className={partner.extraClass}
                  key={index}
                  alt={index}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Partners;

import "./index.css";

const Cards = ({ title, icon, onClick, classes, subtitle }) => {
  return (
    <div onClick={onClick} className={`section__two--cards-item ${classes}`}>
      <div className="logo-parent">
        <div className="logo">
          <img src={icon} alt="" />
        </div>
      </div>
      <div>
        <span className="section__two--cards-item-text">{title}</span>
      </div>
    </div>
  );
};

export default Cards;

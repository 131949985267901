import React from "react";
import { useState,useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import oneriLogo from "../../assets/oneri_logo.png";
import mobilex from "../../assets/mobile-x.svg";
import Sidebar from "../../components/sidebar/Sidebar";
import burger from "../../assets/burgermenu.svg";
import Navbar from "../navbarNews/navbarNews";
import "./Contact.css";
import ContactForm from "../../components/contact-form/ContactForm";
import Map from "../../components/map/Map";
import Title from "../../components/Title";
import Footer from "../footer/footer";

const Contact = ({ isEnglish, setIsEnglish }) => {

  const location = useLocation()
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    window.scrollTo(0,0);
  },[location.pathname]) 

  return (
    <div className="impressum__header">
      <div className={`mobile__menu ${showMenu ? "mobile__show-nav" : ""}`}>
        <img
          style={{ cursor: "pointer", marginTop: "30px", marginLeft: "40px" }}
          src={mobilex}
          alt=""
          onClick={() => setShowMenu(!showMenu)}
        ></img>
        <Sidebar
          show={showMenu}
          setShow={setShowMenu}
          setIsEnglish={setIsEnglish}
          isEnglish={isEnglish}
        />
      </div>
      <div className="stickyheader">
        <header className="header">
          <div className="header__logo" onClick={() => navigate("/")}>
            <img src={oneriLogo} alt="" />
          </div>
          <Navbar
            direction={true}
            isEnglish={isEnglish}
            setIsEnglish={setIsEnglish}
          />
        </header>
        <header className="header__mobile">
          <img onClick={() => setShowMenu(!showMenu)} src={burger} alt="" />
          <div className="header__logo" onClick={() => navigate("/")}>
            <img src={oneriLogo} alt="" />
          </div>
        </header>
      </div>
      <Title header={isEnglish ? 'Contact' : 'Kontakt'}/>
      <p className="contactFormParagraf">{isEnglish ? 'Any question or remarks? Just write us a message!':'Haben Sie Fragen oder Anmerkungen? Schreiben Sie uns einfach eine Nachricht!' }</p>
      <Map isEnglish={isEnglish} />
       <div className="body">
        <Footer isEnglish={isEnglish}/>
       </div>
    </div>
  );
};

export default Contact;